// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row } from "react-bootstrap";

import arrow from "../../../assets/images/arrow_drop_down_green.svg";

const GlobalMetrics = ({ metricsData }) => {
  return (
    <>
      <Row xs={1} md={3} className="cards-deatils g-5">
        {metricsData.map((item, id) => (
          <Col key={id} className="mb-4">
            <Card className="shadowed-box p-0">
              <Card.Body>
                <div>
                  <p className="mb-2">{item.title}</p>
                  <div className="d-flex">
                    <span>{item.value}</span>
                    <span className="profit_analysis d-flex align-items-center gap-2">
                      <img src={arrow} /> {item.profit}
                    </span>
                  </div>
                </div>
                {item.img}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GlobalMetrics;
