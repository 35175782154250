import React, { createContext, useContext, useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { providerHandler } from "../io/kava";
import config from "../config.json";
import { switchNetworkToast } from "../components/toasts/SwitchNetwork";
import { toast } from "react-toastify";

const web3ConnectContext = createContext();

function Web3ConnectProvider({ children }) {
  const { chainId } = useMetaMask();
  const [accountBalance, setAccountBalance] = useState();

  useEffect(() => {
    handleKavaChainChange(chainId);
  }, [chainId]);

  const handleWeb3Connect = async () => {
    console.log('&&&&&&&&777');
    const balance = await providerHandler();
    console.log("balances--", balance);
    setAccountBalance(balance);
  };

  const handleKavaChainChange = async (chainId) => {
    const chainID = config.CHAIN_ID;
    if (chainId !== null && chainId !== chainID) {
      switchNetworkToast(chainID);
      await window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chainID }],
        })
        .then(() => {
          toast.dismiss();
        });
    } else {
      toast.dismiss(chainID);
    }
  };

  return (
    <web3ConnectContext.Provider value={{ handleWeb3Connect, accountBalance }}>{children}</web3ConnectContext.Provider>
  );
}

export default Web3ConnectProvider;

export const useWeb3ConnectContext = () => useContext(web3ConnectContext);
