import React, { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import configs from "../../config.json";
import { useCommonStateContext } from "../../hooks/commonStateContext";

import DownArrowIcon from "../../assets/images/down-arrow-button.svg";
import UpArrowIcon from "../../assets/images/up-arrow-button.svg";
import closeIcon from "../../assets/images/close-icon.svg";

import {
  approve,
  balanceOf,
  fees,
  getApprovedAmount,
  getExecutionFee, getLiquidationPrice,
  getMaxLeverage,
  getMmRatio, getPosLiquidationPrice,
  getTokenPrice,
  placeOrder,
} from "../../io/kava";

import { slippageOptions } from "../static/SlippageOptions";
import { orderTypes } from "../static/OrderTypes";
import { tokenImages } from "../static/SupportedTokens";
import WithTag from "../inputFields/WithTag";
import PlButton from "../buttons/Button";

import CloseConfirmation from "../modals/tradeModals/CloseConfirmation";
import Withdraw from "../modals/tradeModals/Withdraw";
import Deposit from "../modals/tradeModals/Deposit";

import { waitingToast } from "../toasts/Waiting";
import { successToast } from "../toasts/Success";
import { handleError } from "../toasts/Error";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import CustomPagination from "../CustomPagination";


const positionColumnTitles = ["Position", "Size", "Net Value", "Market Price", "Entry Price", "Liquidation Price", "Collateral Asset", "Action"];

const PositionsTab = ({ tableData, setOrderMessage, setCompleteTransactionModal }) => {
  const { account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();
  const { setLoading, setExplorerURL } = useCommonStateContext();

  //to manage data for deposit modal
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [depositOrder, setDepositOrder] = useState();
  const [depositAmount, setDepositAmount] = useState("");

  //to manage data for withdraw modal
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawOrder, setWithdrawOrder] = useState();
  const [withdrawAmount, setWithdrawAmount] = useState("");

  //to manage data for close position modal
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [closeOrder, setCloseOrder] = useState();
  const [closingSize, setClosingSize] = useState("");
  const [orderType, setOrderType] = useState(orderTypes[0]);
  const [reduceType, setReduceType] = useState("reduceCollateral");

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (showWithdrawModal) updateWithdrawValues();
  }, [withdrawAmount, showWithdrawModal]);

  useEffect(() => {
    if (showDepositModal) updateDepositValues();
  }, [depositAmount, showDepositModal]);

  useEffect(() => {
    if (showCloseModal) updateClosingValues();
  }, [closingSize, showCloseModal, orderType, reduceType, closeOrder?.limitMarketPrice]);

  const updateWithdrawValues = async () => {
    let newCollateralValue = Number(withdrawOrder.collateral) - Number(withdrawAmount);
    if (Number(newCollateralValue) < 5) {
      newCollateralValue = Number(withdrawOrder.collateral);
    }
    const { posLiquidationPrice } = await getPosLiquidationPrice(
      withdrawOrder.id,
      withdrawOrder.indexAsset,
      withdrawOrder.side,
      newCollateralValue,
      Number(withdrawOrder.sizeChangeValue),
    );
    setWithdrawOrder({
      ...withdrawOrder,
      newCollateralValue,
      newLeverage: Number(withdrawOrder.sizeChangeValue) / (newCollateralValue + withdrawOrder.estimatedPnl - withdrawOrder.closeFee - withdrawOrder.borrowFee),
      newNetValue: newCollateralValue + withdrawOrder.estimatedPnl - withdrawOrder.closeFee - withdrawOrder.borrowFee,
      newLiquidationPrice: posLiquidationPrice,
      minimumReceived: Number(withdrawAmount) / Number(withdrawOrder.marketPrice),
    });
  };

  const updateDepositValues = async () => {
    let newCollateralValue;
    if (depositOrder.side === 0) {
      newCollateralValue = Number(depositOrder.collateral) + Number(depositAmount) * Number(depositOrder.marketPrice);
    } else {
      const price = await getTokenPrice("USDT");
      newCollateralValue = Number(depositOrder.collateral) + Number(depositAmount) * Number(price);
    }
    const { posLiquidationPrice } = await getPosLiquidationPrice(
      depositOrder.id,
      depositOrder.indexAsset,
      depositOrder.side,
      newCollateralValue,
      Number(depositOrder.sizeChangeValue),
    );
    setDepositOrder({
      ...depositOrder,
      newCollateralValue,
      newLeverage: Number(depositOrder.sizeChangeValue) / (newCollateralValue + depositOrder.estimatedPnl - depositOrder.closeFee - depositOrder.borrowFee),
      newNetValue: newCollateralValue + depositOrder.estimatedPnl - depositOrder.closeFee - depositOrder.borrowFee,
      newLiquidationPrice: posLiquidationPrice,
    });
  };

  const updateClosingValues = async () => {
    console.log("reduceType-", reduceType);
    let newPositionSize = Number(closeOrder.sizeChangeValue) - Number(closingSize);
    let { positionFee } = await fees();
    if (Number(newPositionSize) < 0) {
      newPositionSize = Number(closeOrder.sizeChangeValue);
    }
    const pnl = closeOrder.side === 0
      // priceTrigger  = entryPrice
      ? (Number(orderType.value !== "marketOrder" ? closeOrder.limitMarketPrice : closeOrder.marketPrice) - Number(closeOrder.priceTrigger)) * (newPositionSize / Number(closeOrder.priceTrigger))
      : (Number(closeOrder.priceTrigger) - Number(orderType.value !== "marketOrder" ? closeOrder.limitMarketPrice : closeOrder.marketPrice)) * (newPositionSize / Number(closeOrder.priceTrigger));

    const newPnl = closeOrder.side === 0
      // priceTrigger  = entryPrice
      ? (Number(orderType.value !== "marketOrder" ? closeOrder.limitMarketPrice : closeOrder.marketPrice) - Number(closeOrder.priceTrigger)) * (Number(closingSize) / Number(closeOrder.priceTrigger))
      : (Number(closeOrder.priceTrigger) - Number(orderType.value !== "marketOrder" ? closeOrder.limitMarketPrice : closeOrder.marketPrice)) * (Number(closingSize) / Number(closeOrder.priceTrigger));
    console.log("reduceType--", reduceType);

    if (reduceType === "reduceCollateral") {
      const newNetValue = newPositionSize === 0 ? 0 : newPositionSize / Number(closeOrder.leverage);
      const newCollateralValue = newPositionSize === 0 ? 0 : newNetValue - pnl + (positionFee * newPositionSize) + Number(closeOrder.borrowFee);
      const { posLiquidationPrice } = await getPosLiquidationPrice(
        closeOrder.id,
        closeOrder.indexAsset,
        closeOrder.side,
        newCollateralValue,
        newPositionSize,
      );
      setCloseOrder({
        ...closeOrder,
        newPositionSize,
        newNetValue,
        newCollateralValue,
        newLeverage: newPositionSize === 0 ? 0 : closeOrder.leverage,
        newLiquidationPrice: newPositionSize === 0 ? 0 : posLiquidationPrice,
        estimatedPnl: pnl,
        newEstimatedPnl: newPnl,
        newCloseFee: positionFee * Number(closingSize),
        minimumReceived: (Number(closeOrder.collateral) - newCollateralValue + newPnl - closeOrder.borrowFee - (positionFee * Number(closingSize))) / Number(closeOrder.marketPrice),
      });
    } else {
      const newLeverage = newPositionSize / Number(closeOrder.netValue);
      const newCollateralValue = newLeverage === 0 ? 0 : Number(closeOrder.netValue) - pnl + (positionFee * newPositionSize) + Number(closeOrder.borrowFee);
      const { posLiquidationPrice } = await getPosLiquidationPrice(
        closeOrder.id,
        closeOrder.indexAsset,
        closeOrder.side,
        newCollateralValue,
        newPositionSize,
      );
      setCloseOrder({
        ...closeOrder,
        newPositionSize,
        newCollateralValue,
        newLeverage,
        newNetValue: Number(closeOrder.netValue),
        minimumReceived: newPositionSize === 0 ? (Number(closeOrder.collateral) + newPnl - closeOrder.borrowFee - (positionFee * Number(closingSize))) / Number(closeOrder.marketPrice) : 0,
        newLiquidationPrice: newLeverage === 0 ? 0 : posLiquidationPrice,
        newCloseFee: positionFee * Number(closingSize),
        newEstimatedPnl: newPnl,
      });
    }
  };

  const handleShowDeposit = async (order) => {
    setDepositAmount("");
    setDepositOrder({
      ...order,
      executionFee: await getExecutionFee(),
      balance: order.collateralAsset === configs.nativeToken ? accountBalance : await balanceOf(order.collateralAsset, account),
      approvedBalance: order.collateralAsset === configs.nativeToken ? accountBalance : await getApprovedAmount(account, order.collateralAsset, configs.trade.orderManager),
      slippage: slippageOptions[0],
    });
    setShowDepositModal(true);
  };

  const handleShowWithdraw = async (order) => {
    setWithdrawAmount("");
    const maxLeverage = await getMaxLeverage();
    setWithdrawOrder({
      ...order,
      executionFee: await getExecutionFee(),
      balance:
        Number(order.collateral) - Number(order.sizeChangeValue) / maxLeverage >= Number(order.collateral) - 5
          ? Number(order.collateral) - 5
          : Number(order.collateral) - Number(order.sizeChangeValue) / maxLeverage,
      slippage: slippageOptions[0],
    });
    setShowWithdrawModal(true);
  };

  const handleShowCloseModal = async (order) => {
    setClosingSize("");
    setCloseOrder({
      ...order,
      slippage: slippageOptions[0],
      limitMarketPrice: order.marketPrice,
      executionFee: await getExecutionFee(),
      minimumReceived: 0,
    });
    setShowCloseModal(true);
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      waitingToast(`Approving ${depositOrder.collateralAsset}`);

      await approve(depositOrder.collateralAsset, configs.trade.orderManager);

      successToast(`${depositOrder.collateralAsset} Approved!`);

      setDepositOrder({
        ...depositOrder,
        approvedBalance: await getApprovedAmount(account, depositOrder.collateralAsset, configs.trade.orderManager),
      });
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleDeposit = async () => {
    setLoading(true);
    let toastMessage;
    if (depositOrder.side === 0) {
      toastMessage = `Requested to deposit $${parseFloat(
        (Number(depositAmount) * Number(depositOrder.marketPrice)).toFixed(3),
      )} to ${depositOrder.indexAsset} Long`;
    } else {
      const price = await getTokenPrice("USDT");
      toastMessage = `Requested to deposit $${parseFloat(
        (Number(depositAmount) * Number(price)).toFixed(3),
      )} to ${depositOrder.indexAsset} Short`;
    }

    try {
      waitingToast(toastMessage);
      const fee = await fees();
      const res = await placeOrder(
        0, // updateType (increase)
        depositOrder.side, // side (long = 0 / short =1)
        depositOrder.indexAsset, //index token
        depositOrder.collateralAsset, // collateral token
        0, // order type (market)
        depositOrder.marketPrice, // order price
        depositOrder.collateralAsset, // pay token
        depositAmount, // purchase / collateral amount
        0, //size change
        fee.positionFee,
        depositOrder.executionFee,
        toastMessage,
      );

      successToast(toastMessage);
      setOrderMessage(toastMessage);
      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setCompleteTransactionModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleWithdraw = async () => {
    setLoading(true);

    const toastMessage = `Requested to withdraw $${parseFloat(Number(withdrawAmount).toFixed(3))} from ${
      withdrawOrder.indexAsset
    } ${withdrawOrder.side === 0 ? "Long" : "Short"}`;

    try {
      waitingToast(toastMessage);
      const fee = await fees();
      const price = await getTokenPrice("USDT");
      console.log("usdPrice---", price);
      const res = await placeOrder(
        1, // updateType (decrease)
        withdrawOrder.side, // side (long = 0 / short =1)
        withdrawOrder.indexAsset, //index token
        withdrawOrder.collateralAsset, // collateral token
        0, // order type (market)
        withdrawOrder.marketPrice, // order price
        withdrawOrder.collateralAsset, // pay token
        withdrawAmount * Number(price), // purchase / collateral amount
        0, //size change
        fee.positionFee,
        withdrawOrder.executionFee,
        toastMessage,
      );

      successToast(toastMessage);
      setOrderMessage(toastMessage);
      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setCompleteTransactionModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleCloseOrder = async () => {
    setLoading(true);
    const toastMessage = `Requested to decrease ${closeOrder.indexAsset} ${closeOrder.side === 0 ? "LONG" : "SHORT"} by $${parseFloat(Number(closingSize).toFixed(3))}`;
    try {
      waitingToast(toastMessage);
      const fee = await fees();

      const res = await placeOrder(
        1, // updateType (decrease)
        closeOrder.side, // side (long = 0 / short =1)
        closeOrder.indexAsset, //index token
        closeOrder.collateralAsset, // collateral token
        orderType.value === "marketOrder" ? 0 : 1, // order type (market = 0 / limit = 1)
        orderType.value === "marketOrder" ? closeOrder.marketPrice : closeOrder.limitMarketPrice, // order price
        closeOrder.collateralAsset, // pay token
        Number(closeOrder.collateral) - closeOrder.newCollateralValue,// purchase / collateral amount
        // (reduceType === "reduceCollateral" || Number(closeOrder.newLeverage) === 0) ? Number(closingSize) / Number(closeOrder.leverage) : 0,
        closingSize, //size change
        fee.positionFee,
        closeOrder.executionFee,
        toastMessage,
        orderType.value !== "marketOrder" && true,
        orderType.value, // market / stop loss / take profit
        )
      ;

      successToast(toastMessage);
      setOrderMessage(toastMessage);
      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setCompleteTransactionModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  let itemsPerPage = 10;
  const pageCount = Math.ceil(tableData?.allPositions?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const currentItems = tableData?.allPositions?.slice(startIndex, startIndex + itemsPerPage);

  const handlePageClick = (selectedPage) => {
    if (selectedPage >= 0 && selectedPage < pageCount) {
      setCurrentPage(selectedPage);
    }
  };


  return (
    <>
      <div className="trading-section history_table_section">
        <table className="w-100">
          {tableData?.allPositions?.length === 0 ? (
            <div className="d-flex align-items-center justify-content-center empty_div w-100">
              <span>You haven't opened any positions yet.</span>
            </div>
          ) : (
            <>
              <tr className="heading swap-content-wrapper">
                {positionColumnTitles.map((column, i) => (
                  <th>{column}</th>
                ))}
              </tr>
              <tbody className="trade-order-wrapper">
              {currentItems?.map((order, i) => (
                <tr className="swap-data data mb-0" key={i}>
                  <td>
                    <div className="d-flex align-items-center gap-1">
                      <img src={tokenImages[order.indexAsset].image} height={20} width={20} />
                      <span className="fw-600">{order.indexAsset}/USD</span>
                      <WithTag
                        isInput={false}
                        tag={order.side === 0 ? "long" : "short"}
                        className={order.side === 0 ? "long" : "short"}
                      />
                    </div>
                  </td>
                  <td>
                    <span>${parseFloat(Number(order.sizeChangeValue).toFixed(2))}</span>
                  </td>
                  <td className={`${!order.isProfit && "loss"}`}>
                    <span>${parseFloat(Number(order.netValue).toFixed(2))}</span>
                  </td>
                  <td>
                    <span>${parseFloat(Number(order.marketPrice).toFixed(3))}</span>
                  </td>
                  <td>
                    <span>${parseFloat(Number(order.priceTrigger).toFixed(3))}</span>
                  </td>
                  <td className={`${order.liquidationPrice < 0 && "loss"}`}>
                    <span> ${parseFloat(Math.abs(order.liquidationPrice).toFixed(3))}</span>
                  </td>
                  <td>
                    <span>{order.collateralAsset}</span>
                  </td>
                  <td className="d-flex align-items-center gap-2">
                    <PlButton
                      onClick={() => handleShowWithdraw(order)}
                      src={DownArrowIcon}
                      className="action-button"
                    />
                    <PlButton
                      onClick={() => handleShowDeposit(order)}
                      src={UpArrowIcon}
                      className="action-button"
                    />
                    <PlButton
                      src={closeIcon}
                      className="action-button"
                      onClick={() => handleShowCloseModal(order)}
                    />
                  </td>
                </tr>
              ))}
              </tbody>
            </>
          )}
        </table>
        {tableData.allPositions?.length > itemsPerPage &&
        <div className="pagination">
          <CustomPagination pageCount={pageCount} onPageChange={handlePageClick} currentPage={currentPage} />
        </div>
        }
      </div>

      {showCloseModal && (
        <CloseConfirmation
          show={showCloseModal}
          onHide={() => setShowCloseModal(false)}
          closeOrder={closeOrder}
          setCloseOrder={setCloseOrder}
          setClosingSize={setClosingSize}
          closingSize={closingSize}
          orderType={orderType}
          setOrderType={setOrderType}
          reduceType={reduceType}
          setReduceType={setReduceType}
          handleCloseOrder={handleCloseOrder}
        />
      )}

      {showWithdrawModal && (
        <Withdraw
          show={showWithdrawModal}
          onHide={() => setShowWithdrawModal(false)}
          withdrawOrder={withdrawOrder}
          setWithdrawOrder={setWithdrawOrder}
          withdrawAmount={withdrawAmount}
          setWithdrawAmount={setWithdrawAmount}
          handleWithdraw={handleWithdraw}
        />
      )}

      {showDepositModal && (
        <Deposit
          show={showDepositModal}
          onHide={() => setShowDepositModal(false)}
          depositOrder={depositOrder}
          setDepositOrder={setDepositOrder}
          depositAmount={depositAmount}
          setDepositAmount={setDepositAmount}
          handleDeposit={handleDeposit}
          handleApprove={handleApprove}
        />
      )}

    </>
  );
};

export default PositionsTab;