
export const toolTipData ={
  slippage:"Setting a high slippage tolerance can help transactions succeed,but you may not get such a good price.Use with caution.",
  targetWeight:"Token weights are adjusted to help hedge LLP holders based on the open positions of traders",
  utilization:"Utilization represents the amount of asset in Lamp pools that is being used to take a margin position at the moment. At 100%, that asset can no longer be used to open any more leveraged positions because all available liquidity has been utilized",
  seniorTranchePrice:"Current USD price of Senior Tranche token",
  mezzanineTranchePrice:"Current USD price of Mezzanine Tranche token",
  juniorTranchePrice:"Current USD price of Junior Tranche token",
  poolLiquidity:"Total amount of liquidity provided to a Liquidity Pool by users",
  trancheApr:"The current annualized return for a Tranche, based on the most recent week return fees and rewards as a run rate",
  minimumReceived:"Your transaction will revert if there is a large, unfavourable price movement before it is confirmed",
  orderType:"- Market order: execute the order immediately at current price. - Limit order: execute the order when the market trades at a specific price.",
  orderPrice:"Asset pricing is powered by Chainlink Oracles.It is an aggregate of prices from leading volume exchanges.",
  orderPay:"Choose how much collateral you wish to allocate to this trade.",
  positionSize:"Size equals leverage times your amount of collateral",
  leverage:"Leverage is calculated by dividing position size by the amount of collateral you wish to allocate for the trade"
}