import { ethers } from "ethers";
import { Contract, Provider } from "ethers-multicall";
import { loadingToast } from "../components/toasts/Loading";

import config from "../config.json";

import orderManagerAbi from "./abis/OrderManager.json";
import poolAbi from "./abis/Pool.json";
import lampMasterAbi from "./abis/LampMasterAbi.json";
import liquidityRouterAbi from "./abis/LiquidityRouterAbi.json";
import oracleAbi from "./abis/Oracle.json";
import erc20Abi from "./abis/ERC20.json";
import trancheAbi from "./abis/Tranche.json";

import platformAbi from "./abis/PlatformAbi.json";
import governanceAbi from "./abis/GovernanceAbi.json";

import batchAuctionFactoryAbi from "./abis/BatchAuctionFactoryAbi.json";
import dutchAuctionFactoryAbi from "./abis/DutchAuctionFactoryAbi.json";
import batchAuctionAbi from "./abis/BatchAuctionAbi.json";
import dutchAuctionAbi from "./abis/DutchAuctionAbi.json";

import governanceStakeAbi from "./abis/GovernanceStakeAbi.json";
import platformStakeAbi from "./abis/PlatformStakeAbi.json";
import governanceRedemptionPoolAbi from "./abis/GovernanceRedemptionPoolAbi.json";

import { formatEther, formatUnits, parseEther, parseUnits } from "ethers/lib/utils";
import { handleDecimals } from "../components/HandleDecimals";

let provider;
let ethcallProvider;

let orderManagerContract;
let poolContract;
let lampMasterContract;
let oracleContract;
let liquidityRouterContract;
let lgoRedemptionPoolContract;
let tokenContracts = {};
let trancheContracts = {};
let auctionContracts = {};
let earnContracts = {};

//for initialize all contracts and get balance of user's wallet
export const providerHandler = async () => {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  const account = await provider.listAccounts();
  const address = account[0];
  const balance = await provider.getBalance(address);
  const signer = provider.getSigner();

  ethcallProvider = new Provider(provider);
  await ethcallProvider.init();

  orderManagerContract = new ethers.Contract(config.trade.orderManager, orderManagerAbi, signer);
  poolContract = new ethers.Contract(config.trade.pool, poolAbi, signer);
  lampMasterContract = new ethers.Contract(config.trade.lampMaster, lampMasterAbi, signer);
  oracleContract = new ethers.Contract(config.trade.oracle, oracleAbi, signer);
  liquidityRouterContract = new ethers.Contract(config.trade.liquidityRouter, liquidityRouterAbi, signer);

  // Token Contracts
  tokenContracts.BTCContract = new ethers.Contract(config.tokens.BTC, erc20Abi, signer);
  tokenContracts.ETHContract = new ethers.Contract(config.tokens.ETH, erc20Abi, signer);
  tokenContracts.KAVAContract = new ethers.Contract(config.tokens.KAVA, erc20Abi, signer);
  tokenContracts.USDTContract = new ethers.Contract(config.tokens.USDT, erc20Abi, signer);
  tokenContracts.GovernanceContract = new ethers.Contract(config.tokens.Governance, erc20Abi, signer);
  tokenContracts.PlatformContract = new ethers.Contract(config.tokens.Platform, erc20Abi, signer);

  // Tranche Contracts
  trancheContracts.seniorTrancheContract = new ethers.Contract(config.tranches.senior, trancheAbi, signer);
  trancheContracts.mezzanineTrancheContract = new ethers.Contract(config.tranches.mezzanine, trancheAbi, signer);
  trancheContracts.juniorTrancheContract = new ethers.Contract(config.tranches.junior, trancheAbi, signer);

  // Auction Contracts
  auctionContracts.batchAuctionContract = new ethers.Contract(
    config.auction.batchAuctionContract,
    batchAuctionFactoryAbi,
    signer,
  );
  auctionContracts.dutchAuctionContract = new ethers.Contract(
    config.auction.duchAuctionContract,
    dutchAuctionFactoryAbi,
    signer,
  );
  auctionContracts.PTcontract = new ethers.Contract(config.auction.platformContract, platformAbi, signer);
  auctionContracts.GTcontract = new ethers.Contract(config.auction.governanceContract, governanceAbi, signer);

  // Earn Contracts
  earnContracts.governanceStake = new ethers.Contract(config.earn.governanceStake, governanceStakeAbi, signer);
  earnContracts.platformStake = new ethers.Contract(config.earn.platformStake, platformStakeAbi, signer);

  //dao Contracts
  lgoRedemptionPoolContract = new ethers.Contract(
    config.dao.governanceRedemptionPool,
    governanceRedemptionPoolAbi,
    signer,
  );

  return ethers.utils.formatEther(balance.toString());
};

// to get balance of addresses like userAddress and poolAddress from specific token's contract
export const balanceOf = async (tokenType, address) => {
  const contract = `${tokenType}Contract`;
  console.log("contract--", contract);
  const n = await tokenContracts[contract].balanceOf(address);
  return ethers.utils.formatEther(n.toString());
};

export const getTokenBalance = async (tokenAddress, userAddress) => {
  const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, provider.getSigner());

  const balance = await tokenContract.balanceOf(userAddress);
  return formatEther(balance.toString());
};

// to get swapFee,positionFee and borrowFee from poolContract
export const fees = async () => {
  let n = await poolContract.fee();
  n = {
    positionFee: ethers.utils.formatUnits(n.positionFee.toString(), 10),
    baseSwapFee: ethers.utils.formatUnits(n.baseSwapFee.toString(), 10),
    trancheFee: ethers.utils.formatUnits(n.taxBasisPoint.toString(), 10),
  };
  return n;
};

export const getLiquidationPrice = async (posData) => {

  const poolMultiContract = new Contract(config.trade.pool, poolAbi);
  let PRECISION = 10 ** 10;
  let posLiquidationPrice;
  const multiCallArr = [
    await poolMultiContract.maintenanceMargin(),
    await poolMultiContract.fee(),
  ];
  const resultArr = await ethcallProvider.all(multiCallArr);

  let maintenanceMarg = Number(resultArr[0]);
  let positionFee = Number(formatUnits(resultArr[1].positionFee.toString(), 10));
  let liquidationFee = Number(formatUnits(resultArr[1].liquidationFee.toString(), 30));

  let posCloseFee = positionFee * posData.posSize / PRECISION;
  let posTotalFee = posCloseFee + liquidationFee;
  // let posTotalFee = posBorrowFee + posCloseFee + liquidationFee;
  console.log("posTotalFee--", posTotalFee);
  console.log("maintenanceMarg--", maintenanceMarg);
  if (posData.side == 0) {
    let liqPrice1 = posData.posEntryPrice * (1 + (maintenanceMarg / PRECISION) - (posData.posCol / posData.posSize));
    let liqPrice2 = posData.posEntryPrice * (1 - (posData.posCol - posTotalFee) / posData.posSize);
    posLiquidationPrice = Math.max(liqPrice1, liqPrice2);
  } else {
    let liqPrice1 = posData.posEntryPrice * (1 - (maintenanceMarg / PRECISION) + (posData.posCol / posData.posSize));
    let liqPrice2 = posData.posEntryPrice * (1 + (posData.posCol - posTotalFee) / posData.posSize);
    posLiquidationPrice = Math.min(liqPrice1, liqPrice2);
  }
  return posLiquidationPrice;
};

export const getPosLiquidationPrice = async (id, token, side, posCol, posSize) => {

  const poolMultiContract = new Contract(config.trade.pool, poolAbi);
  let PRECISION = 10 ** 10;
  let posLiquidationPrice;
  const multiCallArr = [
    await poolMultiContract.positions(id),
    await poolMultiContract.poolTokens(config.tokens[token]),
    await poolMultiContract.maintenanceMargin(),
    await poolMultiContract.fee(),
  ];
  const resultArr = await ethcallProvider.all(multiCallArr);
  // let posSize = Number(formatUnits(resultArr[0].size, 30));
  // let posCol = Number(formatUnits(resultArr[0].collateralValue, 30));
  let posEntryPrice = Number(formatUnits(resultArr[0].entryPrice, 12));
  let posBorrowIdx = Number(resultArr[0].borrowIndex);
  let tokenBorrowIdx = Number(resultArr[1].borrowIndex);
  let maintenanceMarg = resultArr[2];
  let positionFee = Number(formatUnits(resultArr[3].positionFee.toString(), 10));
  let liquidationFee = Number(formatUnits(resultArr[3].liquidationFee.toString(), 30));

  let posBorrowFee = (tokenBorrowIdx - posBorrowIdx) * posSize / PRECISION;
  let posCloseFee = positionFee * posSize / PRECISION;
  let posTotalFee = posBorrowFee + posCloseFee + liquidationFee;
  // let posTotalFee = liquidationFee;

  if (side == 0) {
    let liqPrice1 = posEntryPrice * (1 + (maintenanceMarg / PRECISION) - (posCol / posSize));
    let liqPrice2 = posEntryPrice * (1 - (posCol - posTotalFee) / posSize);
    posLiquidationPrice = Math.max(liqPrice1, liqPrice2);
  } else {
    let liqPrice1 = posEntryPrice * (1 - (maintenanceMarg / PRECISION) + (posCol / posSize));
    let liqPrice2 = posEntryPrice * (1 + (posCol - posTotalFee) / posSize);
    posLiquidationPrice = Math.min(liqPrice1, liqPrice2);
  }

  return { posBorrowFee, posCloseFee: posCloseFee * PRECISION, posLiquidationPrice };
};

//to get executionFee from orderManagerContract
export const getExecutionFee = async () => {
  let n = await orderManagerContract.minPerpetualExecutionFee();
  return ethers.utils.formatEther(n.toString());
};

//to get executionFee from orderManagerContract
export const getSwapExecutionFee = async () => {
  let n = await orderManagerContract.minSwapExecutionFee();
  return ethers.utils.formatEther(n.toString());
};

//to get available liquidity on swap page
export const getLiquidity = async (tokenType) => {
  let n = await poolContract.getPoolAsset(config.tokens[tokenType]);
  return { poolAmount: Number(formatEther(n[0])), reserveAmount: Number(formatEther(n[1])) };
};

// to calculate swap amount according selected tokens and amount
export const getSwapAmount = async (tokenFrom, tokenTo, amount) => {
  console.log("amount--", amount);
  console.log("tokenFrom--", tokenFrom);
  console.log("tokenTo--", tokenTo);
  const n = await poolContract.calcSwapOutput(
    config.tokens[tokenFrom],
    config.tokens[tokenTo],
    parseEther(handleDecimals(amount)),
  );

  return ethers.utils.formatEther(n.amountOut.toString());
};

export const countSwapFee = async (tokenFrom, tokenTo, amount) => {
  console.log("amount--", amount);
  console.log("tokenFrom--", tokenFrom);
  console.log("tokenTo--", tokenTo);
  const n = await poolContract.calcSwapOutput(
    config.tokens[tokenFrom],
    config.tokens[tokenTo],
    parseEther(handleDecimals(amount)),
  );
  console.log("n.feeAmount--", Number(n.feeAmount));
  return ethers.utils.formatEther(n.feeAmount.toString());
};

//to get token amount which is already approved by user according to spenderAddress like pool and orderManager
export const getApprovedAmount = async (userAddress, tokenType, spenderAddress) => {
  const contract = `${tokenType}Contract`;

  const n = await tokenContracts[contract].allowance(userAddress, spenderAddress);
  return ethers.utils.formatEther(n.toString());
};

// to approve token amount according to spenderAddress like pool and orderManager
export const approve = async (tokenType, spenderAddress) => {
  const contract = `${tokenType}Contract`;
  const n = await tokenContracts[contract].approve(spenderAddress, 10);
  loadingToast(`Approving ${tokenType}`);
  await n.wait();
  return n;
};

//to execute market swap order
export const marketSwap = async (tokenFrom, tokenTo, amountIn, minAmountOut, toInputValue) => {
  // console.log("amountIn--", amountIn.toString()));
  console.log("minAmountOut--", minAmountOut);
  console.log("amountIn--", amountIn);
  let n;
  if (tokenFrom === config.nativeToken) {
    n = await orderManagerContract.swap(
      config.nativeAddress,
      config.tokens[tokenTo],
      parseEther(handleDecimals(amountIn)),
      parseEther(handleDecimals(minAmountOut)),
      { value: parseEther(amountIn.toString()) },
    );
  } else if (tokenTo === config.nativeToken) {
    n = await orderManagerContract.swap(
      config.tokens[tokenFrom],
      config.nativeAddress,
      parseEther(handleDecimals(amountIn)),
      parseEther(handleDecimals(minAmountOut)),
    );
  } else {
    n = await orderManagerContract.swap(
      config.tokens[tokenFrom],
      config.tokens[tokenTo],
      parseEther(handleDecimals(amountIn)),
      parseEther(handleDecimals(minAmountOut)),
    );
  }

  loadingToast(
    `Swapping ${parseFloat(Number(amountIn).toFixed(5))} ${tokenFrom} for ${parseFloat(
      Number(toInputValue).toFixed(5),
    )} ${tokenTo}`,
  );

  await n.wait();
  return n;
};

//to place limit swap order
export const limitSwap = async (tokenFrom, tokenTo, amountIn, minAmountOut, price) => {
  const swapExeFee = await getSwapExecutionFee();
  console.log("amountIn--", amountIn);
  console.log("minAmountOut--", minAmountOut);
  console.log("price--", price);
  let n;

  if (tokenFrom === config.nativeToken) {
    const amountWithFee = Number(amountIn) + Number(swapExeFee);
    n = await orderManagerContract.placeSwapOrder(
      config.nativeAddress,
      config.tokens[tokenTo],
      parseEther(handleDecimals(amountIn)),
      parseEther(handleDecimals(minAmountOut)),
      parseUnits(parseFloat(Number(price).toFixed(12)).toString(), "szabo"),
      { value: parseEther(amountWithFee.toString()) },
    );
  } else if (tokenTo === config.nativeToken) {
    n = await orderManagerContract.placeSwapOrder(
      config.tokens[tokenFrom],
      config.nativeAddress,
      parseEther(handleDecimals(amountIn)),
      parseEther(handleDecimals(minAmountOut)),
      parseUnits(parseFloat(Number(price).toFixed(12)).toString(), "szabo"),
      { value: parseEther(swapExeFee) },
    );
  } else {
    n = await orderManagerContract.placeSwapOrder(
      config.tokens[tokenFrom],
      config.tokens[tokenTo],
      parseEther(handleDecimals(amountIn)),
      parseEther(handleDecimals(minAmountOut)),
      parseUnits(parseFloat(Number(price).toFixed(12)).toString(), "szabo"),
      { value: parseEther(swapExeFee) },
    );
  }


  loadingToast(
    `Creating order to swap ${parseFloat(Number(amountIn).toFixed(5))} ${tokenFrom} for ${parseFloat(
      Number(minAmountOut).toFixed(5),
    )} ${tokenTo}`,
  );

  await n.wait();
  return n;
};

// to cancel limit order which is placed by user
export const cancelOrder = async (order) => {
  console.log("order.orderId-", order.orderId);
  const n = await orderManagerContract.cancelSwapOrder(order.orderId);

  loadingToast(
    `Cancelling order to swap ${parseFloat(Number(order.priceIn).toFixed(2))} ${order.assetIn} for ${parseFloat(
      Number(order.priceOut).toFixed(2),
    )} ${order.assetOut}`,
  );

  await n.wait();
  return n;
};

export const getTradeData = async (tokenIn, tokenOut, userAddress, side) => {
  const oracleMultiContract = new Contract(config.trade.oracle, oracleAbi);
  const poolMultiContract = new Contract(config.trade.pool, poolAbi);

  const multiCallArr = [
    await poolMultiContract.getPoolAsset(side === 0 ? config.tokens[tokenOut] : config.tokens.USDT),
    await oracleMultiContract.getPrice(config.tokens[tokenOut], true),
    await oracleMultiContract.getPrice(config.tokens[tokenIn], true),
    await poolMultiContract.maxLeverage(),
    await poolMultiContract.fee(),
  ];

  const resultArr = await ethcallProvider.all(multiCallArr);

  return {
    borrowFeeRate: Number(formatEther(resultArr[0][1])) / Number(formatEther(resultArr[0][0])) * 0.01,
    entryPrice: ethers.utils.formatUnits(resultArr[1], "szabo"),
    // entryPrice: tokenOut === "USDT" ? ethers.utils.formatUnits(resultArr[1], "gwei") * 10 : formatEther(resultArr[1]),
    newEntryPrice: ethers.utils.formatUnits(resultArr[1], "szabo"),
    TokenAPrice: ethers.utils.formatUnits(resultArr[2], "szabo"),
    availableLiquidity: side === 0 ? tokenOut === "KAVA" ?
      Number(formatEther(resultArr[0][0])) - (Number(formatEther(resultArr[0][0])) * 0.7) - Number(formatEther(resultArr[0][1]))
      : Number(formatEther(resultArr[0][0])) - (Number(formatEther(resultArr[0][0])) * 0.05) - Number(formatEther(resultArr[0][1]))
      : Number(formatEther(resultArr[0][0])) - Number(formatEther(resultArr[0][1])),
    maxLeverage: Number(resultArr[3]),
    positionFeeRate: ethers.utils.formatUnits(resultArr[4].positionFee.toString(), 10),
  };
};

export const getOrderData = async (orderId) => {
  return await orderManagerContract.orders(orderId);
};

//to place order in trade page
export const placeOrder = async (
  updateType,
  side,
  indexToken,
  collateralToken,
  orderType,
  orderPrice,
  orderPayToken,
  purchaseAmount,
  sizeChange,
  feeValue,
  executionFee,
  toastMessage,
  isLimit,
  closeType,
) => {
  console.log("updateType--", updateType);
  console.log("side--", side);
  console.log("indexToken--A", indexToken, config.tokens[indexToken]);
  console.log("collateralToken--B", collateralToken, config.tokens[collateralToken]);
  console.log("orderType--", orderType);
  console.log("orderPrice--", orderPrice);
  console.log("purchaseAmount--", purchaseAmount);
  console.log("sizeChange--", sizeChange);
  console.log("feeValue--", feeValue);
  console.log("executionFee--", executionFee);
  console.log("isLimit--", isLimit);

  const triggerAboveThreshold = side === 0 ? closeType === "takeProfit" : closeType === "stopLoss";
  const orderPayTokenAddress = orderPayToken === config.nativeToken ? config.nativeAddress : config.tokens[orderPayToken];
  const purchaseFeeAmount = (orderPayToken === config.nativeToken && updateType === 0) ? Number(purchaseAmount) + Number(executionFee) : executionFee;

  console.log("triggerAboveThreshold--", triggerAboveThreshold);
  console.log("orderPayTokenAddress--", orderPayTokenAddress);
  console.log("purchaseFeeAmount--", parseEther(purchaseFeeAmount.toString()));

  const extraData = ethers.utils.defaultAbiCoder.encode(
    ["uint256", "uint256", "uint256"],
    [
      ethers.utils.parseUnits(sizeChange.toString(), 30),
      parseEther(handleDecimals(purchaseAmount)),
      parseEther(feeValue.toString()),
    ],
  );

  const encodeData = updateType === 0
    //for increase order (new position and deposit)
    // purchaseAmount for increase order = amount of payToken in ETH,BTC,KAVA OR USDT
    ? [
      ethers.utils.parseUnits(parseFloat(Number(orderPrice).toFixed(12)).toString(), "szabo"),
      orderPayTokenAddress,
      parseEther(handleDecimals(purchaseAmount)),
      sizeChange > 0 ? ethers.utils.parseUnits(sizeChange.toString(), 30) : 0,
      parseEther(handleDecimals(purchaseAmount)),
      extraData,
    ]
    // purchaseAmount for decrease order = collateralValue (sizeChange / leverage ) in USD($)
    : isLimit
      // for limit decrease order (close with stopLoss and takeProfit)
      ? [
        ethers.utils.parseUnits(parseFloat(Number(orderPrice).toFixed(12)).toString(), "szabo"),
        triggerAboveThreshold,
        orderPayTokenAddress,
        ethers.utils.parseUnits(sizeChange.toString(), 30),
        ethers.utils.parseUnits(purchaseAmount.toString(), 30), //
        extraData,
      ]
      //for market decrease order (close with market Price and withdraw)
      : [
        ethers.utils.parseUnits(parseFloat(Number(orderPrice).toFixed(12)).toString(), "szabo"),
        orderPayTokenAddress,
        sizeChange > 0 ? ethers.utils.parseUnits(sizeChange.toString(), 30) : 0,
        ethers.utils.parseUnits(purchaseAmount.toString(), 30),
        extraData,
      ];
  console.log("ff", ethers.utils.defaultAbiCoder.encode(
    updateType === 0
      ? ["uint256", "address", "uint256", "uint256", "uint256", "bytes"]
      : isLimit
      ? ["uint256", "bool", "address", "uint256", "uint256", "bytes"]
      : ["uint256", "address", "uint256", "uint256", "bytes"],
    encodeData,
  ));

  const n = await orderManagerContract.placeOrder(
    updateType,
    side,
    config.tokens[indexToken],
    config.tokens[collateralToken],
    orderType,
    ethers.utils.defaultAbiCoder.encode(
      updateType === 0
        ? ["uint256", "address", "uint256", "uint256", "uint256", "bytes"]
        : isLimit
        ? ["uint256", "bool", "address", "uint256", "uint256", "bytes"]
        : ["uint256", "address", "uint256", "uint256", "bytes"],
      encodeData,
    ),
    // { value: "203500000000000010" },
    { value: parseEther(purchaseFeeAmount.toString()) },
  );

  loadingToast(toastMessage);

  await n.wait();
  return n;
};

export const getMmRatio = async () => {
  const n = await poolContract.maintenanceMargin();
  return ethers.utils.formatUnits(n, 10);
};

export const getMaxLeverage = async () => {
  const n = await poolContract.maxLeverage();
  return n.toNumber();
};

// to cancel trade order which is placed by user
export const cancelTradeOrder = async (order) => {
  console.log("orderId--", Number(order.id));
  const n = await orderManagerContract.cancelOrder(Number(order.id));

  loadingToast(`Cancelling order to ${order.updateType} ${order.collateralAsset} ${order.side === 0 ? "Long" : "Short"}`);

  await n.wait();
  return n;
};

//to get current price of all tokens from oracle contract
export const getAllTokenPrices = async () => {
  const n = await oracleContract.getMultiplePrices([
    config.tokens.KAVA,
    config.tokens.BTC,
    config.tokens.USDT,
    config.tokens.ETH,
  ], true);
  return await n.map((value, i) =>
    ethers.utils.formatUnits(value, "szabo"),
  );
};

//to get current price of specific token from oracle contract
export const getTokenPrice = async (tokenType) => {
  const n = await oracleContract.getPrice(config.tokens[tokenType], true);
  // if (tokenType === "USDT") {
  //   return ethers.utils.formatUnits(n, "gwei") * 10;
  // } else {
  return ethers.utils.formatUnits(n, "szabo");
  // }
};

//to get target % of al tokens
// export const getTarget = async (address) => {
//   const n = await poolContract.targetWeights(address);
//   return n / 1000;
// };

//get all details of tokens
export const getAssetDetails = async (data, prices, totalValueLocked) => {
  const multiPoolContract = new Contract(config.trade.pool, poolAbi);
  let new_array = [];
  let target_array = [];
  let resultArr = [];

  for (let i = 0; i < data.length; i++) {
    const n = await multiPoolContract.getPoolAsset(config.tokens[data[i].token]);
    const t = await multiPoolContract.targetWeights(config.tokens[data[i].token]);
    new_array.push(n);
    target_array.push(t);
  }

  const multiCallArr = await ethcallProvider.all(new_array);
  const targetMultiCallArr = await ethcallProvider.all(target_array);
  const totalWeight = await poolContract.totalWeight();
  data.map((tokenDetails, i) => {
    tokenDetails.amount = Number(formatEther(multiCallArr[i].poolAmount));
    tokenDetails.price = Number(prices[i]);
    tokenDetails.value = Number(formatEther(multiCallArr[i].poolAmount)) * Number(prices[i]);
    tokenDetails.target = (targetMultiCallArr[i] / Number(totalWeight) * 100).toFixed(2);
    tokenDetails.weight = (Number(formatEther(multiCallArr[i].poolAmount)) * Number(prices[i])) / Number(totalValueLocked) * 100;
    tokenDetails.utilization = Number(formatEther(multiCallArr[i].reservedAmount)) / Number(formatEther(multiCallArr[i].poolAmount)) * 100;
    resultArr.push(tokenDetails);
  });

  return resultArr;
};

export const getTrancheData = async (tokenType, trancheType, spenderAddress, userAddress) => {
  console.log("test--", parseFloat(Number(123.3485).toFixed(18)));
  // const multiTokenContract = new Contract(config.tokens[tokenType], erc20Abi);
  const multiTrancheContract = new Contract(config.tranches[trancheType], trancheAbi);
  // const multiLevelMasterContract = new Contract(config.trade.lampMaster, lampMasterAbi);
  const multiPoolContract = new Contract(config.trade.pool, poolAbi);

  const multiCallArr = [
    // await multiTokenContract.allowance(userAddress, spenderAddress),
    await multiTrancheContract.allowance(userAddress, spenderAddress),
    await multiTrancheContract.allowance(userAddress, config.trade.lampMaster),
    await multiTrancheContract.totalSupply(),
    await multiPoolContract.trancheAssets(config.tranches[trancheType], config.tokens[tokenType]),
    await multiTrancheContract.balanceOf(userAddress),
    await multiPoolContract.targetWeights(config.tokens[tokenType]),
    await multiPoolContract.fee(),
    await multiPoolContract.getTrancheValue(config.tranches[trancheType], true),
    await multiPoolContract.totalWeight(),
    // await multiTokenContract.balanceOf(userAddress),
    // await multiLevelMasterContract.userInfo(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1, userAddress),
    // await multiLevelMasterContract.pendingReward(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1, userAddress),
  ];

  const resultArr = await ethcallProvider.all(multiCallArr);
  console.log("resultArr--", resultArr);
  return {
    // approvedTokenAmount: Number(formatEther(resultArr[0])),
    approvedTrancheAmount: Number(formatEther(resultArr[0])),
    approvedDepositAmount: Number(formatEther(resultArr[1])),
    llpSupply: Number(formatEther(resultArr[2])),
    tokenFund: Number(formatEther(resultArr[3].poolAmount)) - Number(formatEther(resultArr[3].reservedAmount)),
    trancheBalance: formatEther(resultArr[4]),
    targetValue: (resultArr[5] / Number(resultArr[8]) * 100).toFixed(2),
    trancheFee: ethers.utils.formatUnits(resultArr[6].taxBasisPoint.toString(), 10),
    totalLiquidity: Number(ethers.utils.formatUnits(resultArr[7], 30)),
    // tokenBalance: Number(formatEther(resultArr[9])),
    // stakingBalance: Number(formatEther(resultArr[7].amount)),
    // unClaimRewards: Number(formatEther(resultArr[8])),
  };

};

//get all details of tokens according to specific tranche
export const getTokensDetails = async (data, trancheAddress, prices) => {
  const multiPoolContract = new Contract(config.trade.pool, poolAbi);
  let new_array = [];
  const returnData = [];

  for (let i = 0; i < data.length; i++) {
    const n = await multiPoolContract.trancheAssets(trancheAddress, config.tokens[data[i].token]);
    new_array.push(n);
  }

  const finalResponse = await ethcallProvider.all(new_array);
  // console.log("finalResponse--", finalResponse);
  data.map((item, i) => {
    item.amount = Number(formatEther(finalResponse[i].poolAmount));
    item.value = Number(prices[i]) * Number(formatEther(finalResponse[i].poolAmount));
    item.utilization = Number(formatEther(finalResponse[i].reservedAmount)) / Number(formatEther(finalResponse[i].poolAmount)) * 100;
    returnData.push(item);
  });

  return { assetData: returnData };
};

export const getTranchesDetails = async (tradingTrancheData) => {
  const multiPoolContract = new Contract(config.trade.pool, poolAbi);
  // const multiLampMasterContract = new Contract(config.trade.lampMaster, lampMasterAbi);
  const trancheContracts = {};
  trancheContracts.seniorMultiContract = new Contract(config.tranches.senior, trancheAbi);
  trancheContracts.mezzanineMultiContract = new Contract(config.tranches.mezzanine, trancheAbi);
  trancheContracts.juniorMultiContract = new Contract(config.tranches.junior, trancheAbi);
  let new_array = [];
  let supply = [];
  let points = [];

  for (let i = 0; i < tradingTrancheData.length; i++) {
    let contract = `${tradingTrancheData[i].type}MultiContract`;
    const s = await trancheContracts[contract].totalSupply();
    const n = await multiPoolContract.getTrancheValue(config.tranches[tradingTrancheData[i].type], true);
    const p = await lampMasterContract.poolInfo(i);
    new_array.push(n);
    supply.push(s);
    points.push(p);
  }
  let finalResponse = await ethcallProvider.all(new_array);
  let supplyResponse = await ethcallProvider.all(supply);
  // let poolAllocPoints = await ethcallProvider.all(points);
  let totalAllocPoint = await lampMasterContract.totalAllocPoint();
  let rewardPerSec = await lampMasterContract.rewardPerSecond();
  console.log("totalAllocPoint-", Number(totalAllocPoint));
  console.log("rewardPerSec-", Number(rewardPerSec));

  const returnData = [];
  await tradingTrancheData.map((trancheData, i) => {
    console.log("points-", Number(points[i].allocPoint));
    trancheData.liquidity = Number(formatUnits(finalResponse[i], 30));
    trancheData.price = Number(formatUnits(finalResponse[i], 30)) / Number(formatEther(supplyResponse[i]));
    trancheData.dailyPoolLampReward = Math.round(Number(formatUnits(rewardPerSec, 18)) * 86400 * Number(points[i].allocPoint) / Number(totalAllocPoint));
    returnData.push(trancheData);
  });
  console.log("returnData-", returnData);
  return returnData;
};

//to get rewards(earn per day) of specific tranche
export const getDailyLampReward = async (trancheId) => {
  const poolAllocPoint = await lampMasterContract.poolInfo(trancheId);
  let totalAllocPoint = await lampMasterContract.totalAllocPoint();
  let rewardPerSec = await lampMasterContract.rewardPerSecond();
  return Math.round(Number(formatUnits(rewardPerSec, 18)) * 86400 * Number(poolAllocPoint.allocPoint) / Number(totalAllocPoint));
};

//to get supply of all tranche
export const trancheSupply = async () => {
  const n = await poolContract.getPoolValue(true);
  return ethers.utils.formatUnits(n, 30);
};

//to get balance of user from specific trance contract
export const trancheBalance = async (trancheType, userAddress) => {
  const contract = `${trancheType}TrancheContract`;
  const n = await trancheContracts[contract].balanceOf(userAddress);
  return ethers.utils.formatEther(n);
};

export const stakeBalance = async (trancheId, userAddress) => {
  const n = await lampMasterContract.userInfo(trancheId, userAddress);
  console.log("n.amount-", ethers.utils.formatEther(n.amount));
  return ethers.utils.formatEther(n.amount);
};

//to get tranche amount which is already approved by user according to spenderAddress like pool
export const approvedTranche = async (trancheType, userAddress, spenderAddress) => {
  const contract = `${trancheType}TrancheContract`;
  const n = await trancheContracts[contract].allowance(userAddress, spenderAddress);
  return ethers.utils.formatEther(n);
};

// to approve tranche amount according to spenderAddress like pool
export const poolApprove = async (trancheType, spenderAddress) => {
  const contract = `${trancheType}TrancheContract`;
  // console.log("spenderAddress-", spenderAddress);
  const n = await trancheContracts[contract].approve(spenderAddress, 10);

  loadingToast(`Approving ${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp`);
  await n.wait();
  return n;
};

//to calculate Liquidity from token to tranche
export const calcAddLiquidity = async (trancheAddress, tokenType, amountIn) => {
  console.log("trancheAddress--", trancheAddress);
  console.log("amountIn--", amountIn);
  console.log("tokenType--", config.tokens[tokenType]);

  const multiPoolContract = new Contract(config.trade.pool, poolAbi);
  const multiTrancheContract = new Contract(trancheAddress, trancheAbi);
  const multiOracleContract = new Contract(config.trade.oracle, oracleAbi);

  const multiCallArr = [
    await multiPoolContract.calcAddLiquidity(trancheAddress,
      config.tokens[tokenType],
      parseEther(handleDecimals(amountIn))),
    await multiTrancheContract.totalSupply(),
    await multiPoolContract.getTrancheValue(trancheAddress, true),
    await multiOracleContract._getPrice(config.tokens[tokenType]),
  ];
  const resultArr = await ethcallProvider.all(multiCallArr);
  let LP_INITIAL_VALUE = 1 ** 12;

  let lpAmount = Number(formatEther(resultArr[0].lpAmount.toString()));
  let lpSupply = Number(formatEther(resultArr[1]));
  let trancheValue = Number(formatUnits(resultArr[2], 30));
  let tokenPrice = Number(formatUnits(resultArr[3], 12));

  let userAmount = (lpSupply === 0 || trancheValue === 0)
    ? lpAmount * LP_INITIAL_VALUE / tokenPrice
    : lpAmount * trancheValue / (tokenPrice * lpSupply);

  let addLiqFeeRate = 1 - (userAmount / Number(amountIn));

  return {
    lpAmount,
    daoFee: addLiqFeeRate,
  };
};

//to calculate Liquidity from tranche to token
export const calcRemoveLiquidity = async (trancheAddress, tokenType, lpAmount) => {
  console.log("trancheAddress--", trancheAddress);
  console.log("lpAmount--", lpAmount);
  const multiPoolContract = new Contract(config.trade.pool, poolAbi);
  const multiCallArr = [
    await multiPoolContract.calcRemoveLiquidity(
      trancheAddress,
      config.tokens[tokenType],
      parseEther(handleDecimals(lpAmount)),
    )
  ]
  const resultArr = await ethcallProvider.all(multiCallArr);

  let outAmountAfterFee = Number(formatEther(resultArr[0].outAmountAfterFee.toString()));
  let outAmount = Number(formatEther(resultArr[0].outAmount.toString()));

  let removeLiqFeeRate = 1 - (outAmountAfterFee / outAmount);

  return {
    lpAmount: outAmountAfterFee,
    daoFee: removeLiqFeeRate,
  };
};

//to buy tranche amount
export const addLiquidity = async (trancheType, tokenType, amountIn, minimumAmount, userAddress, amountOut, isSwitchOn) => {
  let n;
  console.log("trancheType--", trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2);
  console.log("minimumAmount--", minimumAmount);
  console.log("amountIn--", amountIn);
  console.log("userAddress--", userAddress);
  console.log("isSwitchOn--", isSwitchOn);

  if (isSwitchOn) {
    if (tokenType === config.nativeToken) {
      console.log("####### -  lampMaster add eth liquidity");
      n = await lampMasterContract.addLiquidityETH(
        trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
        parseEther(handleDecimals(minimumAmount)),
        userAddress,
        { value: parseEther(amountIn.toString()) },
      );
    } else {
      console.log("####### - lampMaster add liquidity");
      // stake LLP tokens
      n = await lampMasterContract.addLiquidity(
        trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
        config.tokens[tokenType],
        parseEther(handleDecimals(amountIn)),
        parseEther(handleDecimals(minimumAmount)),
        userAddress,
      );
    }
  } else {
    // buy LLP tokens
    if (tokenType === config.nativeToken) {
      console.log("####### - liquidityRouter ETH add liquidity");
      n = await liquidityRouterContract.addLiquidityETH(
        config.tranches[trancheType],
        // parseEther(parseFloat(Number(amountIn).toFixed(18)).toString()),
        parseEther(handleDecimals(minimumAmount)),
        userAddress,
        { value: parseEther(amountIn.toString()) },
      );
    } else {
      console.log("####### - liquidityRouter add liquidity");
      n = await liquidityRouterContract.addLiquidity(
        config.tranches[trancheType],
        config.tokens[tokenType],
        parseEther(handleDecimals(amountIn)),
        parseEther(handleDecimals(minimumAmount)),
        userAddress,
      );
    }
  }

  loadingToast(
    ` Selling ${parseFloat(Number(amountIn).toFixed(5))} ${tokenType}  to get ${parseFloat(Number(amountOut).toFixed(5))} ${
      trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} LLP`,
  );

  await n.wait();
  return n;
};

//to sell tranche amount
export const removeLiquidity = async (trancheType, tokenType, lpAmountIn, minimumAmount, userAddress, amountOut, isSwitchOn) => {
  console.log("userAddress-", userAddress);
  console.log("lpAmountIn-", lpAmountIn);
  console.log("minimumAmount-", minimumAmount);
  console.log("trancheType-", trancheType);
  console.log("tokenType-", tokenType);
  let n;
  if (isSwitchOn) {
    if (tokenType === config.nativeToken) {
      console.log("#########");
      n = await lampMasterContract.removeLiquidityETH(
        trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
        parseEther(handleDecimals(lpAmountIn)),
        parseEther(handleDecimals(minimumAmount)),
        userAddress,
      );
    } else {
      console.log("####### - lampMaster remove liquidity");
      n = await lampMasterContract.removeLiquidity(
        //withdraw and sell LLP tokens from staking balance
        trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2,
        parseEther(handleDecimals(lpAmountIn)),
        config.tokens[tokenType],
        parseEther(handleDecimals(minimumAmount)),
        userAddress,
      );
    }
  } else {

    // sell LLP tokens from wallet balance
    if (tokenType === config.nativeToken) {
      console.log("####### - liquidityRouter ETH remove liquidity");
      n = await liquidityRouterContract.removeLiquidityETH(
        config.tranches[trancheType],
        parseEther(handleDecimals(lpAmountIn)),
        parseEther(handleDecimals(minimumAmount)),
        userAddress,
      );
    } else {
      console.log("####### - liquidityRouter remove liquidity");
      n = await liquidityRouterContract.removeLiquidity(
        config.tranches[trancheType],
        config.tokens[tokenType],
        parseEther(handleDecimals(lpAmountIn)),
        parseEther(handleDecimals(minimumAmount)),
        userAddress,
      );
    }
  }

  loadingToast(
    ` Selling ${parseFloat(Number(lpAmountIn).toFixed(5))} ${
      trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
    } LLP to get ${parseFloat(Number(amountOut).toFixed(5))} ${tokenType}`,
  );

  await n.wait();
  return n;
};

export const depositTranche = async (trancheId, amount, userAddress) => {
  const n = await lampMasterContract.deposit(trancheId, parseEther(handleDecimals(amount)), userAddress);
  loadingToast(`Depositing ${amount} ${trancheId === 0 ? "Senior" : trancheId === 2 ? "Junior" : "Mezzanine"} llp.`);
  await n.wait();
  return n;
};

export const withdrawTranche = async (trancheId, amount, userAddress) => {
  const n = await lampMasterContract.withdraw(trancheId, parseEther(handleDecimals(amount)), userAddress);
  loadingToast(`Withdrawing ${amount} ${trancheId === 0 ? "Senior" : trancheId === 2 ? "Junior" : "Mezzanine"} llp.`);
  await n.wait();
  return n;
};

export const trancheRewards = async (trancheId, userAddress) => {
  console.log("trancheId--", trancheId);
  console.log("userAddress--", userAddress);
  const n = await lampMasterContract.pendingReward(trancheId, userAddress);

  return formatEther(n.toString());
};

export const trancheClaim = async (trancheId, userAddress) => {
  console.log("trancheId--", trancheId);
  const n = await lampMasterContract.harvest(trancheId, userAddress);
  loadingToast(`Claiming Lamp`);
  await n.wait();
  return n;
};

// Auction Functions
export const getAuctions = async () => {
  const currentTime = parseInt(Date.now() / 1000);
  const signer = provider.getSigner();

  const batchAuctions = await auctionContracts.batchAuctionContract.totalAuctions().then((res) => {
    return res.toNumber();
  });
  const dutchAuctions = await auctionContracts.dutchAuctionContract.totalAuctions().then((res) => {
    return res.toNumber();
  });

  let batchAuctionAddresses = [];
  let dutchAuctionAddresses = [];

  const liveAuction = [];
  const upcomingAuction = [];
  const finishedAuction = [];

  if (batchAuctions) {
    const batchAuctionMultiCall = new Contract(config.auction.batchAuctionContract, batchAuctionFactoryAbi);

    const multicallArr = [];
    for (let i = 0; i < batchAuctions; i++) multicallArr.push(await batchAuctionMultiCall.auctions(i));

    batchAuctionAddresses = await ethcallProvider.all(multicallArr);
  }

  for (let i = 0; i < batchAuctions; i++) {
    let auctionData = {
      address: batchAuctionAddresses[i],
      method: "Batch Auction",
    };

    const batchAuctionContract = new Contract(batchAuctionAddresses[i], batchAuctionAbi);

    const multicallArr = [
      await batchAuctionContract.endTime(),
      await batchAuctionContract.startTime(),
      await batchAuctionContract.auctionToken(),
      await batchAuctionContract.totalTokens(),
      await batchAuctionContract.payToken(),
      await batchAuctionContract.startPrice(),
      await batchAuctionContract.vestingDuration(),
      await batchAuctionContract.tokenPrice(),
      await batchAuctionContract.minimumPrice(),
      await batchAuctionContract.commitmentsTotal(),
    ];

    const resultArr = await ethcallProvider.all(multicallArr);

    auctionData.endTime = resultArr[0].toNumber();
    auctionData.startTime = resultArr[1].toNumber();
    auctionData.auctionAssetAddress = resultArr[2];
    auctionData.totalSupply = formatEther(resultArr[3]);
    auctionData.payTokenAddress = resultArr[4];
    auctionData.startPrice = formatEther(resultArr[5]);
    auctionData.vesting = resultArr[6];
    auctionData.tokenPrice = formatEther(resultArr[6]);
    auctionData.floorPrice = formatEther(resultArr[7]);
    auctionData.totalRaised = formatEther(resultArr[8]);

    const auctionAssetContract = new ethers.Contract(resultArr[2], erc20Abi, signer);
    auctionData.auctionAsset = await auctionAssetContract.symbol();

    const payTokenContract = new ethers.Contract(resultArr[4], erc20Abi, signer);
    auctionData.payToken = await payTokenContract.symbol();

    if (auctionData.endTime > currentTime && auctionData.startTime < currentTime) liveAuction.push(auctionData);

    if (auctionData.endTime > currentTime && auctionData.startTime > currentTime) upcomingAuction.push(auctionData);

    if (auctionData.endTime < currentTime && auctionData.startTime < currentTime) finishedAuction.push(auctionData);
  }

  if (dutchAuctions) {
    const dutchAuctionMultiCall = new Contract(config.auction.duchAuctionContract, dutchAuctionFactoryAbi);

    const multicallArr = [];
    for (let i = 0; i < dutchAuctions; i++) multicallArr.push(await dutchAuctionMultiCall.auctions(i));

    dutchAuctionAddresses = await ethcallProvider.all(multicallArr);
  }

  for (let i = 0; i < dutchAuctions; i++) {
    let auctionData = {
      address: dutchAuctionAddresses[i],
      method: "Dutch Auction",
      vesting: false,
    };

    const dutchAuctionContract = new Contract(dutchAuctionAddresses[i], dutchAuctionAbi);

    const multicallArr = [
      await dutchAuctionContract.endTime(),
      await dutchAuctionContract.startTime(),
      await dutchAuctionContract.auctionToken(),
      await dutchAuctionContract.totalTokens(),
      await dutchAuctionContract.payToken(),
      await dutchAuctionContract.startPrice(),
      await dutchAuctionContract.tokenPrice(),
      await dutchAuctionContract.minimumPrice(),
      await dutchAuctionContract.commitmentsTotal(),
    ];

    const resultArr = await ethcallProvider.all(multicallArr);

    auctionData.endTime = resultArr[0].toNumber();
    auctionData.startTime = resultArr[1].toNumber();
    auctionData.auctionAssetAddress = resultArr[2];
    auctionData.totalSupply = formatEther(resultArr[3]);
    auctionData.payTokenAddress = resultArr[4];
    auctionData.startPrice = formatEther(resultArr[5]);
    auctionData.tokenPrice = formatEther(resultArr[6]);
    auctionData.floorPrice = formatEther(resultArr[7]);
    auctionData.totalRaised = formatEther(resultArr[8]);

    const auctionAssetContract = new ethers.Contract(resultArr[2], erc20Abi, signer);
    auctionData.auctionAsset = await auctionAssetContract.symbol();

    const payTokenContract = new ethers.Contract(resultArr[4], erc20Abi, signer);
    auctionData.payToken = await payTokenContract.symbol();

    if (auctionData.endTime > currentTime && auctionData.startTime < currentTime) liveAuction.push(auctionData);

    if (auctionData.endTime > currentTime && auctionData.startTime > currentTime) upcomingAuction.push(auctionData);

    if (auctionData.endTime < currentTime && auctionData.startTime < currentTime) finishedAuction.push(auctionData);
  }

  return {
    liveAuction: liveAuction,
    upcomingAuction: upcomingAuction,
    finishedAuction: finishedAuction,
  };
};

export const getUserAuctionData = async (auctionAddress, method, account) => {
  const auctionContract = new Contract(auctionAddress, method === "Dutch Auction" ? dutchAuctionAbi : batchAuctionAbi);

  const resultArr = await ethcallProvider.all([
    await auctionContract.commitments(account),
    await auctionContract.claimed(account),
  ]);

  return {
    commitments: formatEther(resultArr[0]),
    claimed: formatEther(resultArr[1]),
  };
};

export const approveTokenSpend = async (tokenAddress, spenderAddress, amount) => {
  const signer = provider.getSigner();
  const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, signer);

  const n = await tokenContract.approve(spenderAddress, amount);
  await n.wait();

  return n;
};

export const commitTokensForAuction = async (auctionAddress, method, userAddress, amount) => {
  const signer = provider.getSigner();
  const auctionContract = new ethers.Contract(
    auctionAddress,
    method === "Dutch Auction" ? dutchAuctionAbi : batchAuctionAbi,
    signer,
  );

  const n = await auctionContract.commitTokens(userAddress, parseEther(amount));
  await n.wait();

  return n;
};

// Earn Functions
export const getEarnData = async (account) => {
  const governanceTokenContract = new Contract(config.tokens.Governance, erc20Abi);
  const platformTokenContract = new Contract(config.tokens.Platform, erc20Abi);
  const governanceStakeContract = new Contract(config.earn.governanceStake, governanceStakeAbi);
  const platformStakeContract = new Contract(config.earn.platformStake, platformStakeAbi);

  const multicallArr = [
    await governanceTokenContract.balanceOf(config.earn.governanceStake),
    await platformTokenContract.balanceOf(config.earn.platformStake),
    await governanceStakeContract.getRewardsPerSecond(),
    await platformStakeContract.rewardsPerSecond(),
    await platformStakeContract.stakingTax(),
  ];

  if (account) {
    multicallArr.push(
      await governanceStakeContract.userInfo(account),
      await platformStakeContract.userInfo(account),
      await governanceStakeContract.pendingRewards(account),
      await platformStakeContract.pendingRewards(account),
      await governanceTokenContract.balanceOf(account),
      await platformTokenContract.balanceOf(account),
      await governanceTokenContract.allowance(account, config.earn.governanceStake),
      await platformTokenContract.allowance(account, config.earn.platformStake),
    );
  }
  const resultArr = await ethcallProvider.all(multicallArr);

  return {
    governance: {
      deposit: formatEther(resultArr[0]),
      reward: formatEther(resultArr[2].mul(86400)),
      userDeposit: formatEther(resultArr[5].amount),
      userReward: formatEther(resultArr[7]),
      userBalance: formatEther(resultArr[9]),
      approvedBalance: formatEther(resultArr[11]),
    },
    platform: {
      deposit: formatEther(resultArr[1]),
      reward: formatEther(resultArr[3].mul(86400)),
      userDeposit: formatEther(resultArr[6].amount),
      userReward: formatEther(resultArr[8]),
      stakingTax: formatEther(resultArr[4]),
      userBalance: formatEther(resultArr[10]),
      approvedBalance: formatEther(resultArr[12]),
    },
  };
};

export const depositedGovernance = async () => {
  const n = await tokenContracts.GovernanceContract.balanceOf(config.earn.governanceStake);
  return formatEther(n);
};

export const depositedPlatform = async () => {
  const n = await tokenContracts.PlatformContract.balanceOf(config.earn.platformStake);
  return formatEther(n);
};

export const approvedGovernance = async (userAddress) => {
  const n = await tokenContracts.GovernanceContract.allowance(userAddress, config.earn.governanceStake);
  return formatEther(n);
};

export const approvedPlatform = async (userAddress) => {
  const n = await tokenContracts.PlatformContract.allowance(userAddress, config.earn.platformStake);
  return formatEther(n);
};

export const governanceApprove = async () => {
  const n = await tokenContracts.GovernanceContract.approve(config.earn.governanceStake, 10);
  loadingToast("Approving Governance.");
  await n.wait();
  return n;
};

export const platformApprove = async () => {
  const n = await tokenContracts.PlatformContract.approve(config.earn.platformStake, 10);
  loadingToast("Approving Platform.");
  await n.wait();
  return n;
};


export const governanceDeposit = async (account, amount) => {
  const n = await earnContracts.governanceStake.stake(account, parseEther(amount.toString()));
  loadingToast(`Depositing ${amount} Governance.`);
  await n.wait();

  return n;
};

export const governanceWithdraw = async (account, amount) => {
  const n = await earnContracts.governanceStake.unstake(account, parseEther(amount.toString()));
  loadingToast(`Withdrawing ${amount} Governance.`);
  await n.wait();
  return n;
};

export const governanceClaim = async (account) => {
  const n = await earnContracts.governanceStake.claimRewards(account);
  loadingToast(`Claiming Governance.`);
  await n.wait();

  return n;
};

export const platformDeposit = async (account, amount) => {
  console.log("amount-", amount);
  const n = await earnContracts.platformStake.stake(account, parseEther(amount.toString()));
  loadingToast(`Depositing ${amount} Platform.`);
  await n.wait();

  return n;
};

export const platformWithdraw = async (account, amount) => {
  const n = await earnContracts.platformStake.unstake(account, parseEther(amount.toString()));
  loadingToast(`Withdrawing ${amount} Platform.`);
  await n.wait();

  return n;
};

export const platformClaim = async (account) => {
  const n = await earnContracts.platformStake.claimRewards(account);
  loadingToast("Claiming Platform.");
  await n.wait();

  return n;
};

// Dao functions
export const getLvlBalance = async (userAddress) => {
  const n = await auctionContracts.PTcontract.balanceOf(userAddress);
  return ethers.utils.formatEther(n);
};

export const getApprovedLvl = async (userAddress, spenderAddress) => {
  console.log("tokenContracts.LAMPContract-", tokenContracts.PlatformContract);
  const n = await auctionContracts.PTcontract.allowance(userAddress, spenderAddress);
  return ethers.utils.formatEther(n.toString());
};

export const lvlApprove = async (spenderAddress) => {
  const n = await auctionContracts.PTcontract.approve(spenderAddress, 10);
  loadingToast(`Approving Lamp`);
  await n.wait();
  return n;
};

export const getStakedBalance = async (userAddress) => {
  const n = await earnContracts.platformStake.userInfo(userAddress);
  return ethers.utils.formatEther(n.amount.toString());
};

export const getPendingRewards = async (userAddress) => {
  const n = await earnContracts.platformStake.pendingRewards(userAddress);
  return ethers.utils.formatEther(n);
};

export const lvlStake = async (userAddress, amount) => {
  const n = await earnContracts.platformStake.stake(userAddress, parseEther(amount));
  loadingToast(`Staking ${amount} Lamp`);
  await n.wait();

  return n;
};

export const lvlUnstake = async (userAddress, amount) => {
  const n = await earnContracts.platformStake.unstake(userAddress, parseEther(amount));
  loadingToast(`Unstaking ${amount} Lamp`);
  await n.wait();

  return n;
};

export const lvlClaimRewards = async (userAddress) => {
  console.log("userAddress-", userAddress);
  const n = await earnContracts.platformStake.claimRewards(userAddress);
  await n.wait();

  return n;
};

export const getLgoBalance = async (userAddress) => {
  const n = await auctionContracts.GTcontract.balanceOf(userAddress);
  return ethers.utils.formatEther(n);
};

export const getApprovedLgo = async (userAddress, spenderAddress) => {
  const n = await auctionContracts.GTcontract.allowance(userAddress, spenderAddress);
  return ethers.utils.formatEther(n.toString());
};

export const lgoApprove = async (spenderAddress) => {
  const n = await auctionContracts.GTcontract.approve(spenderAddress, 10);
  loadingToast(`Approving LGO`);
  await n.wait();
  return n;
};

export const lvlRedeem = async (userAddress, amount) => {
  const n = await lgoRedemptionPoolContract.redeem(userAddress, parseEther(amount));
  await n.wait();

  return n;
};
