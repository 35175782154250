const { Button: Button } = require("react-bootstrap");

const PlButton = ({ type, label, className, onClick, height, width, disabled = false, src }) => {
  switch (type) {
    case "percentageButton":
      return (
        <Button onClick={onClick} className={`percentage-btn ${className}`}>
          {label}
        </Button>
      );

    default:
      return (
        <Button variant="secondary" className={`pl-btn ${className}`} disabled={disabled} onClick={onClick}>
          {src ? <img height={height} width={width} src={src}></img> : label}
        </Button>
      );
  }
};

export default PlButton;
