import { toast } from "react-toastify";
import waitImg from "../../assets/images/waiting-icon.svg";
import React from "react";
import { toastOptions } from "./ToastOptions";

export const waitingToast = (message) => {
  toast(
    <div className="d-flex align-items-center justify-content-center gap-2 flex-column">
      <p className="toast_heading">
        <img src={waitImg} alt="wait" /> Waiting for confirmation
      </p>
      <p className="toast_message">{message}</p>
    </div>,
    {
      ...toastOptions,
      autoClose: false,
      type: "warning",
      toastId: "waiting",
      style: { color: "#E6BB00", backgroundColor: "#272320" },
    }
  );
};