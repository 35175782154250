// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row } from "react-bootstrap";

const PlatformMetrics = ({ metricsData }) => {
  return (
    <>
      <Row xs={1} md={3} className="cards-deatils g-5">
        {metricsData.map((item, id) => (
          <Col key={id}>
            <Card className="shadowed-box p-0">
              <Card.Body>
                <div>
                  <p className="mb-2">{item.title}</p>
                  <span>${Math.round(item.value).toLocaleString()}</span>
                </div>
                {item.img}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PlatformMetrics;
