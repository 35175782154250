const DefaultInput = ({ label, disabled, placeholder, inputValue, onChange, className,inputClass }) => {
  return (
    <>
      {label && <label className='input_label'>{label}</label>}
      <div className={`input-wrapper ${className} ${disabled && "disabled"}`}>
        <input
          type="number"
          onKeyPress={(event) => {
            if (event.charCode < 48 && event.charCode !== 46) {
              event.preventDefault();
            }
          }}
          min="0"
          placeholder={placeholder}
          value={inputValue}
          onChange={onChange}
          disabled={disabled}
          className={inputClass}
        />
      </div>
    </>
  );
};

export default DefaultInput;
