// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useLazyQuery } from "@apollo/client";
import Loader from "../../components/loader/Loader";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import { useTradeTokenContext } from "../../hooks/tradeTokenContext";
import configs from "../../config.json";
import Page from "./Page";

import {
  balanceOf,
  calcSwapFee,
  countSwapFee, getApprovedAmount,
  getExecutionFee,
  getMmRatio,
  getSwapExecutionFee, getTokenPrice,
  getTradeData,
} from "../../io/kava";
import { TRADE_VOLUME, GET_BLOCK, oracleValues, tokenPrices } from "../../io/subgraph";

import { posSizeTokens, supportedTokens } from "../../components/static/SupportedTokens";
import { slippageOptions } from "../../components/static/SlippageOptions";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import { ethers } from "ethers";
import config from "../../config.json";
import { formatEther } from "ethers/lib/utils";

const Container = () => {
  const { setLoading, loading } = useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();
  const { account, chainId } = useMetaMask();

  // If the user selects a pair directly from the dashboard page, this token will be displayed in the position size input field.
  const { tradeToken } = useTradeTokenContext();

  // To save the selected leverage amount from the range input.
  const [leverage, setLeverage] = useState(2);

  //To store the current price and last 24-hour changes of the selected token in order to display them above the chart.
  const [tradingDetails, setTradingDetails] = useState({});

  //user input the amount to swap
  const [amountIn, setAmountIn] = useState("");

  // to set all details of selected tokens and tab
  const [tradeConditions, setTradeConditions] = useState({
    //selected token in amount inputField
    selectedFrom: supportedTokens[0],
    //selected token in position size inputField
    selectedTo: tradeToken ? posSizeTokens.find((token) => token.value === tradeToken) : posSizeTokens[2],
    slippage: slippageOptions[0],
    selectedTradeTab: "long",
  });

  const [stats] = useLazyQuery(TRADE_VOLUME, {
    context: { clientName: "analytics" },
  });
  const [blocks] = useLazyQuery(GET_BLOCK, {
    context: { clientName: "analytics" },
  });

  useEffect(() => {
    if (accountBalance !== undefined) {
      fetchTradeConditions();
    }
  }, [accountBalance, account, chainId, tradeConditions.selectedTo, tradeConditions.selectedTradeTab]);

  useEffect(() => {
    getPayTokenDetails();
  }, [tradeConditions.selectedFrom]);


  useEffect(() => {
    const interval = setInterval(() => {
      getPrices();
    }, 10000);

    return () => clearInterval(interval);
  });

  const getPrices = async () => {
    const fromPrice = await getTokenPrice(tradeConditions.selectedFrom.value);
    const toPrice = await getTokenPrice(tradeConditions.selectedTo.value);
    console.log("entryPrice--", toPrice);
    setTradeConditions({
      ...tradeConditions,
      TokenAPrice: fromPrice,
      entryPrice: toPrice,
    });
  };

  const getPayTokenDetails = async () => {
    const fromPrice = await getTokenPrice(tradeConditions.selectedFrom.value);
    const tokenBalance = tradeConditions.selectedFrom.value === config.nativeToken ? accountBalance : await balanceOf(tradeConditions.selectedFrom.value, account);
    const approvedBalance = tradeConditions.selectedFrom.value === config.nativeToken ? accountBalance : await getApprovedAmount(
      account,
      tradeConditions.selectedFrom.value,
      configs.trade.orderManager,
    );

    setTradeConditions({
      ...tradeConditions,
      TokenAPrice: fromPrice,
      tokenBalance,
      approvedAmount: Number(approvedBalance),
    });
  };

  const fetchTradeConditions = async () => {
    setLoading(true);

    const prices = await oracleValues();

    let tokenPriceChangeDetails = {};
    prices.map((priceDetails) => {
      if (
        priceDetails.token === "BNB"
          ? "KAVA" === tradeConditions.selectedTo.value
          : priceDetails.token === tradeConditions.selectedTo.value
      ) {
        tokenPriceChangeDetails = {
          ...priceDetails,
          change: Number(priceDetails.close) - Number(priceDetails.open),
        };
      }
    });
    // console.log("tokenPriceChangeDetails--", tokenPriceChangeDetails);
    const { data: blockData } = await blocks();

    // To obtain the price changes of the last 24 hours.
    const { data } = await stats({
      variables: {
        b24: Number(blockData._meta.block.number) - configs.blockCount,
      },
    });

    const currentVolume = `trade${configs.tokens[tradeConditions.selectedTo.value].toLowerCase()}`;
    const pastVolume = `trade${configs.tokens[tradeConditions.selectedTo.value].toLowerCase()}yesterday`;

    const volumeData = {
      volume:
        data[currentVolume].length > 0 && data[pastVolume].length > 0
          ? (Number(data[currentVolume][0].volume) - Number(data[pastVolume][0].volume)) / configs.divideVolume
          : 0,
      usdVolume:
        data[currentVolume].length > 0 && data[pastVolume].length > 0
          ? (Number(data[currentVolume][0].volumeUsd) - Number(data[pastVolume][0].volumeUsd)) / configs.divideValue
          : 0,
    };

    const tokenCurrentPrices = await tokenPrices();
    const selectedTokenPrices = await tokenCurrentPrices.find((currentPriceDetail) =>
      currentPriceDetail.token === "BNB"
        ? "KAVA" === tradeConditions.selectedTo.value
        : currentPriceDetail.token === tradeConditions.selectedTo.value,
    );

    tokenPriceChangeDetails = {
      ...tokenPriceChangeDetails,
      ...volumeData,
      token: tokenPriceChangeDetails.token === "BNB" ? "KAVA" : tokenPriceChangeDetails.token,
      price: selectedTokenPrices.price,
      changePercentage: (tokenPriceChangeDetails.change / Number(selectedTokenPrices.price)) * 100,
    };

    setTradingDetails(tokenPriceChangeDetails);

    // const executionFee =0;
    const executionFee = await getExecutionFee();
    // const swapExeFee = 0;
    const swapExeFee = await getSwapExecutionFee();
    const tradeData = await getTradeData(
      tradeConditions.selectedFrom.value,
      tradeConditions.selectedTo.value,
      account,
      tradeConditions.selectedTradeTab === "long" ? 0 : 1);
    // console.log("tradeData-", tradeData);
    const tokenBalance = tradeConditions.selectedFrom.value === config.nativeToken ? accountBalance : await balanceOf(tradeConditions.selectedFrom.value, account);
    const approvedBalance = tradeConditions.selectedFrom.value === config.nativeToken ? accountBalance : await getApprovedAmount(
      account,
      tradeConditions.selectedFrom.value,
      configs.trade.orderManager,
    );

    // Set all data of the selected tokens, including balance, liquidity, and fee.
    setTradeConditions({
      ...tradeConditions,
      ...tradeData,
      tokenBalance,
      approvedAmount: Number(approvedBalance),
      executionFee,
      swapExecutionFee: swapExeFee,
      baseSwapFee: "0.65",
    });

    setLoading(false);
  };


  return (
    <div className="trade container">
      <Loader loading={loading} />
      <Page
        tradingDetails={tradingDetails}
        tradeConditions={tradeConditions}
        setTradeConditions={setTradeConditions}
        amountIn={amountIn}
        setAmountIn={setAmountIn}
        leverage={leverage}
        setLeverage={setLeverage}
      />
    </div>
  );
};

export default Container;
