import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import closeIcon from "../../../assets/images/modal-close-icon.svg";

import SelectField from "../../inputFields/SelectField";
import { useEffect } from "react";
import DefaultInput from "../../inputFields/DefaultInput";
import PlButton from "../../buttons/Button";
import WithTag from "../../inputFields/WithTag";
import { PercentageButtons } from "../../buttons/Percentage";
import { SlippageRow } from "../../SlippageRow";
import { tokenImages } from "../../static/SupportedTokens";

const CloseConfirmation = (props) => {
  const {
    show,
    onHide,
    closeOrder,
    setCloseOrder,
    orderType,
    setOrderType,
    closingSize,
    setClosingSize,
    reduceType,
    setReduceType,
    handleCloseOrder,
  } = props;
  console.log("closeOrder.limitMarketPrice-", closeOrder.limitMarketPrice);

  const closePositionInfo = [
    {
      title: "Entry Price",
      value: `$${parseFloat(Number(closeOrder.priceTrigger).toFixed(2))}`,
    },
    {
      title: "Market Price",
      value: `$${parseFloat(Number(closeOrder.marketPrice).toFixed(2))}`,
    },
    {
      title: "Liquidation Price",
      oldValue: `$${parseFloat(Math.abs(closeOrder.liquidationPrice).toFixed(3))} → `,
      value: `$${parseFloat(Math.abs(closeOrder.newLiquidationPrice).toFixed(3))}`,
      style: {
        color: closeOrder.liquidationPrice < 0 && "red",
      },
    },
    {
      title: "Position Size",
      oldValue: `$${parseFloat(Number(closeOrder.sizeChangeValue).toFixed(2))}  → `,
      value: `$${parseFloat(Number(closeOrder.newPositionSize).toFixed(3))}`,
    },
    {
      title: "Collateral Value",
      oldValue: `$${parseFloat(Number(closeOrder.collateral).toFixed(2))} → `,
      value: `$${parseFloat(Number(closeOrder.newCollateralValue).toFixed(3))}`,
    },
    {
      title: "Net Value",
      oldValue: `$${parseFloat(Number(closeOrder.netValue).toFixed(2))} → `,
      value: `$${parseFloat(Number(closeOrder.newNetValue).toFixed(3))}`,
    },
    {
      title: "Leverage",
      oldValue: `${parseFloat(Number(closeOrder.leverage).toFixed(2))}x → `,
      value: `${closeOrder.newLeverage === 0 ? "-" : `${parseFloat(Number(closeOrder.newLeverage).toFixed(2))}x`}`,
    },
    {
      title: "Minimum PnL",
      value: `${Number(closeOrder.newEstimatedPnl) < 0 ? "-" : ""}$${parseFloat(
        Math.abs(Number(closeOrder.newEstimatedPnl)).toFixed(2),
      )}`,
      style: {
        color: Number(closeOrder.newEstimatedPnl) < 0 && "#FF0000",
      },
    },
    {
      title: "Borrow Fee",
      value: `$${closeOrder.borrowFee.toFixed(2)}`,
    },
    {
      title: "Close Fee",
      value: closeOrder.newCloseFee === 0 ? "-" : `$${closeOrder.newCloseFee < 0.01 ? "<0.01" : closeOrder.newCloseFee?.toFixed(2)}`,
    },
    {
      title: "Execution Fee",
      value: `${closeOrder?.executionFee} KAVA`,
    },
    {
      title: "Minimum Receive",
      value:
        closeOrder?.minimumReceived > 0
          ? `${parseFloat(Number(closeOrder.minimumReceived).toFixed(5))} ${closeOrder?.collateralAsset}`
          : "-",
    },
  ];

  useEffect(() => {
    console.log("CANCEL DATA", closeOrder);
  }, [closeOrder]);

  return (
    <Modal {...props} size="lg" centered show={show}>
      <Modal.Body>
        <div className="close-icon" onClick={onHide}>
          <img src={closeIcon} />
        </div>

        <div className="modal-title">
          <h3>Close Position</h3>
        </div>

        <div className="token-header">
          <p className="position">
            <span className="d-flex align-items-center justify-content-center me-2">
              <img src={tokenImages[closeOrder.indexAsset].image} height={22} width={22} />
              {/*<img className="trade_head_img" height={22} width={22} src={tokenImages.USD.image} />*/}
            </span>
            <span className="fw-600">{closeOrder.indexAsset}/USD</span>
            <WithTag
              isInput={false}
              tag={closeOrder.side === 0 ? "long" : "short"}
              className={closeOrder.side === 0 ? "long" : "short"}
            />
          </p>
          <p className="collateral">
            Collateral Asset:
            <span>
              <img src={tokenImages[closeOrder.collateralAsset].image} height={22} width={22} />
              {closeOrder?.collateralAsset}
            </span>
          </p>
        </div>

        <div className="form-container">
          <form>
            <SelectField
              label={"Order Type"}
              placeholder={"Market Order"}
              optionValue={[
                {
                  label: (
                    <div className="dropdown_label d-flex align-items-center fw-normal order_options">Market Order</div>
                  ),
                  value: "marketOrder",
                },
                {
                  label: (
                    <div className="dropdown_label d-flex align-items-center fw-normal order_options">Take Profit</div>
                  ),
                  value: "takeProfit",
                },
                {
                  label: (
                    <div className="dropdown_label d-flex align-items-center fw-normal order_options">Stop Loss</div>
                  ),
                  value: "stopLoss",
                },
              ]}
              value={orderType}
              onSelectChange={(val) => setOrderType(val)}
            />
            <DefaultInput
              label={"Price"}
              placeholder={"0.0"}
              inputValue={orderType.value !== "marketOrder" ? closeOrder.limitMarketPrice : closeOrder.marketPrice}
              onChange={(e) =>
                setCloseOrder({
                  ...closeOrder,
                  limitMarketPrice: e.target.value.length > 0 ? e.target.value : closeOrder.marketPrice,
                })
              }
              inputClass={`${orderType.value === "marketOrder" && "cursor_not_allowed"}`}
            />
            <WithTag
              isInput
              label={"Closing Size"}
              placeholder={"0.0"}
              tag={"USD"}
              inputValue={closingSize}
              onChange={(e) => setClosingSize(e.target.value)}
            />
            <div className="btn-container">
              <p>Max Closing Size: {Number(closeOrder.sizeChangeValue).toFixed(2)} USD</p>
              <PercentageButtons balance={closeOrder.sizeChangeValue} setAmountIn={setClosingSize} />
            </div>
            <SlippageRow allDetails={closeOrder} setAllDetails={setCloseOrder} />
            <PlButton
              disabled={
                Number(closingSize) === 0 ||
                (Number(closeOrder.newPositionSize) !== 0 && Number(closeOrder?.newCollateralValue) < 5) ||
                (Number(closeOrder.newPositionSize) !== 0 && Number(closeOrder?.newLeverage) < 2) ||
                Number(closingSize) > Number(closeOrder.sizeChangeValue)
              }
              onClick={() => {
                handleCloseOrder();
                onHide();
              }}
              label={
                Number(closingSize) === 0
                  ? "enter an amount"
                  : Number(closeOrder.newPositionSize) !== 0 && Number(closeOrder?.newCollateralValue) < 5
                  ? "min collateral : $5"
                  : Number(closeOrder.newPositionSize) !== 0 && Number(closeOrder?.newLeverage) < 2
                    ? "min leverage : 2x"
                    : Number(closingSize) > Number(closeOrder.sizeChangeValue)
                      ? "insufficient funds"
                      : "CLOSE"
              }
            />
          </form>

          <div className="info-section">
            {closePositionInfo.map((data, i) => (
              <span key={i}>
                <p>{data.title}</p>
                <p style={data.style} className="d-flex align-items-center gap-3">
                  {data?.oldValue && <span className="opacity-50">{data.oldValue}</span>} {data.value}
                </p>
              </span>
            ))}
          </div>
          <div className="size-input">
            <Form.Check
              type="radio"
              value="reduceCollateral"
              label={`Reduce size and keep leverage at ${parseFloat(Number(closeOrder.leverage).toFixed(2))}x`}
              name="size"
              checked={reduceType === "reduceCollateral"}
              onChange={(e) => setReduceType(e.target.value)}
            />
            <Form.Check
              type="radio"
              value="reduceLeverage"
              label="Reduce size and leverage without withdrawing collateral"
              name="size"
              checked={reduceType === "reduceLeverage"}
              onChange={(e) => setReduceType(e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CloseConfirmation;