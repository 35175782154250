import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useCommonStateContext } from "../../../hooks/commonStateContext";
import { useMetaMask } from "metamask-react";
import {
  approve,
  countSwapFee,
  getApprovedAmount,
  getLiquidationPrice,
  placeOrder,
} from "../../../io/kava";
import configs from "../../../config.json";

import PlButton from "../../../components/buttons/Button";
import Confirm from "../../../components/modals/Confirm";
import CompleteTransaction from "../../../components/modals/CompleteTransaction";
import { orderTypes } from "../../../components/static/OrderTypes";
import { AllDataRows, AmountDetails, LeverageComp, OrderPrice, PositionSize } from "./commonComponents";
import { PercentageButtons } from "../../../components/buttons/Percentage";
import { SlippageRow } from "../../../components/SlippageRow";
import { waitingToast } from "../../../components/toasts/Waiting";

import { successToast } from "../../../components/toasts/Success";
import { handleError } from "../../../components/toasts/Error";
import ReactTooltip from "react-tooltip";

const PlaceOrder = ({
                      tradeConditions,
                      setTradeConditions,
                      amountIn,
                      setAmountIn,
                      leverage,
                      setLeverage,
                    }) => {
  // To display or hide the confirmation modal.
  const [tradeConfirmModal, setTradeConfirmModal] = useState(false);
  const [swapFee, setSwapFee] = useState(0);
  const { account } = useMetaMask();

  const [liquidationPrice, setLiquidationPrice] = useState("");
  const [positionFeeValue, setPositionFeeValue] = useState(0);

  //The user receives the swapped amount based on the amountIn, leverage, and selected token's price.
  const [amountOut, setAmountOut] = useState("");

  const [sizeChange, setSizeChange] = useState("");

  // Multiply the price of the selectedFrom token by the amountIn.
  const [collateralValue, setCollateralValue] = useState("");

  // To handle the selected order type, such as limit and market.
  const [orderType, setOrderType] = useState(orderTypes[0]);
  const {
    setUsdValue,
    showConfirmModal,
    handleCloseSuccessModal,
    setLoading,
    usdValue,
    setExplorerURL,
    setShowConfirmModal,
  } = useCommonStateContext();


  useEffect(() => {
    countValues();
  }, [
    amountIn,
    swapFee,
    leverage,
    tradeConditions.TokenAPrice,
    tradeConditions.newEntryPrice,
    tradeConditions.entryPrice,
    orderType,
    tradeConditions.selectedTradeTab,
  ]);

  useEffect(() => {
    let isUnmounted = false;
    //to count swapFee
    const coutSwapFee = async () => {
      let feeAmount;
      // console.log("#########");
      try {
        if (tradeConditions.selectedTradeTab === "long" && tradeConditions.selectedFrom.value !== tradeConditions.selectedTo.value) {
          feeAmount = await countSwapFee(tradeConditions.selectedFrom.value, tradeConditions.selectedTo.value, amountIn);
        } else if (tradeConditions.selectedTradeTab === "short" && tradeConditions.selectedFrom.value !== "USDT") {
          feeAmount = await countSwapFee(tradeConditions.selectedFrom.value, "USDT", amountIn);
        } else {
          feeAmount = 0;
        }

        if (!isUnmounted) {
          feeAmount = Number(feeAmount) / Number(amountIn) * 100;
          setSwapFee(feeAmount);
        }

      } catch (e) {
        setSwapFee(0);
      }

    };
    coutSwapFee();
    return () => {
      isUnmounted = true;
    };
  }, [amountIn]);

  useEffect(() => {
    if (account !== null) {
      console.log("$$$$$$$$$$$");
      // countValues();
      countLiquidationPrice();
    }
  }, [
    collateralValue,
    sizeChange,
  ]);

  //to count collateralValue,positionSize,positionFeeCount,amountOut
  const countValues = async () => {

    const valueIn = Number(tradeConditions.TokenAPrice) * Number(amountIn);
    console.log("test----valueIn---", valueIn);
    const swapFeeCount = valueIn * swapFee / 100;
    // console.log('tradeConditions--',tradeConditions);
    let positionSize = (valueIn - swapFeeCount) * leverage / (1 + Number(tradeConditions.positionFeeRate) * leverage);
    positionSize = positionSize * Number(orderType.value === "marketOrder" ? tradeConditions.entryPrice : tradeConditions.newEntryPrice) / Number(tradeConditions.entryPrice);
    setSizeChange(positionSize);
    console.log("test----positionSize--", positionSize);
    const positionFeeCount = positionSize * Number(tradeConditions.positionFeeRate);
    setPositionFeeValue(positionFeeCount);

    const amount = positionSize / Number(orderType.value === "marketOrder" ? tradeConditions.entryPrice : tradeConditions.newEntryPrice);

    if (amount > 0) {
      setAmountOut(Number(amount));
    } else {
      setAmountOut("");
    }

    if (valueIn !== 0) {
      setCollateralValue(valueIn - positionFeeCount - swapFeeCount);
    } else {
      setCollateralValue("");
    }
  };

  //to calculate liquidation price
  const countLiquidationPrice = async () => {
    const liqPrice = await getLiquidationPrice({
      side: tradeConditions.selectedTradeTab === "long" ? 0 : 1,
      posEntryPrice: Number(orderType.value === "marketOrder" ? tradeConditions.entryPrice : tradeConditions.newEntryPrice),
      posCol: Number(collateralValue),
      posSize: Number(sizeChange),
    });
    console.log("liquidationPrice--", liqPrice);
    if (Number(liqPrice) > 0) {
      setLiquidationPrice(liqPrice);
    } else {
      setLiquidationPrice("");
    }
  };

  const handleShowModal = async () => {
    setTradeConfirmModal(true);

    //To calculate the USD value based on the provided amount and the selected token's price.
    let getFrom;
    let getTo;
    getFrom = Number(tradeConditions?.TokenAPrice) * Number(amountIn);
    getTo = sizeChange;

    setUsdValue({
      fromUsdValue: parseFloat(Number(getFrom).toFixed(2)),
      toUsdValue: parseFloat(Number(getTo).toFixed(2)),
    });
  };

  //to approve amount of selected asset(from)
  const handleApprove = async () => {
    setLoading(true);
    try {
      waitingToast(`Approving ${tradeConditions.selectedFrom.value}`);

      await approve(tradeConditions.selectedFrom.value, configs.trade.orderManager);

      successToast(`${tradeConditions.selectedFrom.value} Approved!`);

      const approvedAmt = await getApprovedAmount(
        account,
        tradeConditions.selectedFrom.value,
        configs.trade.orderManager,
      );
      setTradeConditions({
        ...tradeConditions,
        approvedAmount: Number(approvedAmt),
      });
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  //to place order in trade
  const handleTrade = async () => {
    setLoading(true);
    try {
      waitingToast(
        `Requesting to increase ${tradeConditions.selectedTo.value} ${tradeConditions.selectedTradeTab} by $${usdValue.toUsdValue}`,
      );

      const res = await placeOrder(
        0, // updateType(increase = 0 / decrease = 1)
        tradeConditions.selectedTradeTab === "long" ? 0 : 1, // side (long = 0 / short = 1)
        tradeConditions.selectedTo.value, // index token
        tradeConditions.selectedTradeTab === "long" ? tradeConditions.selectedTo.value : "USDT", // collateral token
        orderType.value === "marketOrder" ? 0 : 1, // orderType ( market = 0 / limit = 1)
        orderType.value === "marketOrder" ? tradeConditions.entryPrice : tradeConditions.newEntryPrice, // order Price
        tradeConditions.selectedFrom.value, // pay token
        amountIn, //purchase / collateral amount
        sizeChange, // sizeChange
        tradeConditions.positionFeeRate,
        tradeConditions.selectedTradeTab === "long"
          ? tradeConditions.selectedFrom !== tradeConditions.selectedTo
          ? Number(tradeConditions.executionFee) + Number(tradeConditions.swapExecutionFee)
          : tradeConditions.executionFee
          : tradeConditions.selectedFrom !== "USDT"
          ? Number(tradeConditions.executionFee) + Number(tradeConditions.swapExecutionFee)
          : tradeConditions.executionFee,
        `Requesting to increase ${tradeConditions.selectedTo.value} ${tradeConditions.selectedTradeTab} by $${usdValue.toUsdValue}`,
      );

      successToast(
        `Requested to increase ${tradeConditions.selectedTo.value} ${tradeConditions.selectedTradeTab} by $${usdValue.toUsdValue}`,
      );

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="shadowed-box trading">
        <Tabs
          defaultActiveKey="long"
          onSelect={(k) => setTradeConditions({ ...tradeConditions, selectedTradeTab: k })}
          activeKey={tradeConditions.selectedTradeTab}
          id="uncontrolled-tab-example"
          className="mb-3 swap_card_tabs trade_tabs"
        >
          <Tab eventKey="long" title="LONG">
            <PositionSize
              tradeConditions={tradeConditions}
              setTradeConditions={setTradeConditions}
            />
            <OrderPrice orderType={orderType}
                        setOrderType={setOrderType}
                        setTradeConditions={setTradeConditions}
                        tradeConditions={tradeConditions}
            />
            <AmountDetails
              setTradeConditions={setTradeConditions}
              tradeConditions={tradeConditions}
              setAmountIn={setAmountIn}
              amountIn={amountIn}
            />

            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">
                Bal: {tradeConditions?.tokenBalance ? parseFloat(Number(tradeConditions?.tokenBalance)?.toFixed(3)) : "-"} {tradeConditions.selectedFrom.value}
              </span>
              <PercentageButtons setAmountIn={setAmountIn} balance={tradeConditions?.tokenBalance} />
            </div>
            <LeverageComp leverage={leverage} setLeverage={setLeverage} tradeConditions={tradeConditions} />
            <div className="mt-2">
              <SlippageRow setAllDetails={setTradeConditions} allDetails={tradeConditions} />
            </div>


            <p className="position_size_data d-flex justify-content-between align-items-center mt-4">
              Position Size<span
              className="fw-600">${Number(sizeChange) > 0 ? parseFloat(Number(sizeChange).toFixed(3)) : "0"} </span>
            </p>

            <div className="mt-4">
              <PlButton
                disabled={
                  Number(amountIn) === 0 ||
                  Number(amountIn) > tradeConditions.tokenBalance ||
                  Number(collateralValue) < 5 ||
                  Number(tradeConditions?.availableLiquidity) < Number(amountOut)
                }
                label={
                  Number(amountIn) === 0
                    ? "enter an amount"
                    : Number(amountIn) > tradeConditions.tokenBalance
                    ? "insufficient funds"
                    : Number(tradeConditions?.availableLiquidity) < Number(amountOut)
                      ? "insufficient liquidity"
                      : Number(collateralValue) < 5
                        ? "MIN COLLATERAL VALUE: $5"
                        : Number(amountIn) > tradeConditions.approvedAmount
                          ? `APPROVE ${tradeConditions.selectedFrom.value}`
                          : `BUY ${tradeConditions.selectedTo.value}`
                }
                onClick={Number(amountIn) > tradeConditions.approvedAmount ? handleApprove : handleShowModal}
              />
            </div>

            <AllDataRows
              tradeConditions={tradeConditions}
              liquidationPrice={liquidationPrice}
              leverage={leverage}
              swapFee={swapFee}
              orderType={orderType}
              collateralValue={collateralValue}
            />
          </Tab>
          <Tab eventKey="short" title="SHORT">
            <PositionSize
              tradeConditions={tradeConditions}
              setTradeConditions={setTradeConditions}
            />
            <OrderPrice orderType={orderType}
                        setOrderType={setOrderType}
                        setTradeConditions={setTradeConditions}
                        tradeConditions={tradeConditions}
            />
            <AmountDetails
              setTradeConditions={setTradeConditions}
              tradeConditions={tradeConditions}
              setAmountIn={setAmountIn}
              amountIn={amountIn}
            />

            <div className="d-flex justify-content-between align-items-center mt-2">
              <span className="balance">
                Bal: {tradeConditions?.tokenBalance ? parseFloat(Number(tradeConditions?.tokenBalance)?.toFixed(3)) : "-"} {tradeConditions.selectedFrom.value}
              </span>
              <PercentageButtons setAmountIn={setAmountIn} balance={tradeConditions?.tokenBalance} />
            </div>

            <LeverageComp leverage={leverage} setLeverage={setLeverage} tradeConditions={tradeConditions} />
            <div className="mt-3">
              <SlippageRow setAllDetails={setTradeConditions} allDetails={tradeConditions} />
            </div>

            <p className="position_size_data d-flex justify-content-between align-items-center mt-4">
              Position Size<span
              className="fw-600">${Number(sizeChange) > 0 ? parseFloat(Number(sizeChange).toFixed(3)) : "0"} </span>
            </p>

            <div className="mt-4">
              <PlButton
                disabled={
                  Number(amountIn) <= 0 ||
                  Number(amountIn) > tradeConditions.tokenBalance ||
                  Number(collateralValue) < 5 ||
                  Number(tradeConditions?.availableLiquidity) < Number(amountOut)
                }
                label={
                  Number(amountIn) <= 0
                    ? "enter an amount"
                    : Number(amountIn) > tradeConditions.tokenBalance
                    ? "insufficient funds"
                    : Number(tradeConditions?.availableLiquidity) < Number(amountOut)
                      ? "insufficient liquidity"
                      : Number(collateralValue) < 5
                        ? "MIN COLLATERAL VALUE: $5"
                        : Number(amountIn) > tradeConditions.approvedAmount
                          ? `APPROVE ${tradeConditions.selectedFrom.value}`
                          : `SELL ${tradeConditions.selectedTo.value}`
                }
                onClick={Number(amountIn) > tradeConditions.approvedAmount ? handleApprove : handleShowModal}
              />
            </div>
            <AllDataRows
              tradeConditions={tradeConditions}
              liquidationPrice={liquidationPrice}
              swapFee={swapFee}
              leverage={leverage}
              orderType={orderType}
              collateralValue={collateralValue}
            />
          </Tab>
        </Tabs>
      </div>

      {tradeConfirmModal && (
        <Confirm
          show={tradeConfirmModal}
          data={true}
          title={`${tradeConditions.selectedTradeTab.toUpperCase()} ${tradeConditions.selectedTo.value}`}
          onHide={() => setTradeConfirmModal(false)}
          orderType={orderType}
          modalDetails={tradeConditions}
          amountIn={amountIn}
          amountOut={amountOut}
          positionFeeValue={positionFeeValue}
          handleTrade={handleTrade}
        />
      )}
      <ReactTooltip backgroundColor="black" className="react_tooltip" arrowColor="black" place="top" type="dark"
                    effect="solid" />
      {showConfirmModal && (
        <CompleteTransaction
          show={showConfirmModal}
          onHide={() => handleCloseSuccessModal()}
          tradeConditions={tradeConditions}
        />
      )}
    </>
  );
};

export default PlaceOrder;
