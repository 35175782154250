import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useMetaMask } from "metamask-react";
import { ethers } from "ethers";
import { Tab, Tabs } from "react-bootstrap";
import configs from "../config.json";
import loader from "../assets/images/loader.gif";

import { GET_TRADE_HISTORIES, GET_TRADE_POSITIONS } from "../io/subgraph";
import {
  getOrderData,
  getPosLiquidationPrice,
  getTokenPrice,
} from "../io/kava";

import CompleteTransaction from "./modals/CompleteTransaction";

import HistoryTab from "./tradeTabs/HistoryTab";
import OrdersTab from "./tradeTabs/OrdersTab";
import PositionsTab from "./tradeTabs/PositionsTab";
import { useWeb3ConnectContext } from "../hooks/web3ConnectContext";
import loaderSvg from "../assets/images/icons8-refresh.svg";

const TradeDetail = () => {
  const { chainId, account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();
  const [orderMessage, setOrderMessage] = useState("");
  const [dataFetching, setDataFetching] = useState(false);
  const [completeTransactionModal, setCompleteTransactionModal] = useState(false);

  //to set all trade orders from subgraph
  const [tableData, setTableData] = useState({});

  const [orderHistory, { refetch }] = useLazyQuery(GET_TRADE_HISTORIES, {
    variables: { owner: account, skip: 0, first: 1000 },
  });
  const [positions, { refetch: reRender }] = useLazyQuery(GET_TRADE_POSITIONS, {
    variables: { owner: account },
    context: { clientName: "orders" },
  });

  useEffect(() => {
    if (accountBalance !== undefined) {
      fetchOrderDetails();
    }
  }, [account, accountBalance, chainId]);

  const fetchOrderDetails = async () => {
    setDataFetching(true);
    const { data } = await orderHistory();
    const { data: positionData } = await positions();
    const unixTime = (time) => {
      var u = new Date(time * 1000);
      return u.toLocaleString();
    };

    let history = [];
    data?.histories?.map((order) => {
      if (order.updateType !== "SWAP" && order.status !== "LIQUIDATED") {
        const ID = order.id.split("-")[0];
        const getIndexTokenDetails = positionData?.perpOrders.find((o) => Number(o.id) === Number(ID));
        let updatedOrder = {
          ...order,
          collateralAsset: configs.tokensType[order.collateralToken.substring(1)],
          priceTrigger: ethers.utils.formatEther(order.triggerPrice),
          sizeChangeValue: ethers.utils.formatUnits(order.size, 30),
          collateral: ethers.utils.formatUnits(order.collateralValue, 30),
          dateTime: unixTime(order.createdAtTimestamp),
        };

        if (getIndexTokenDetails) {
          updatedOrder.indexAsset = configs.tokensType[getIndexTokenDetails.indexToken.substring(1)];
        } else {
          const openOrder = history?.find((o) => o.id.split("-")[0] === order.id.split("-")[0]);
          updatedOrder.indexAsset = openOrder.indexAsset;
        }

        history.push(updatedOrder);
      }
    });

    let ordersData = [];
    for (let i = 0; i < data?.orders.length; i++) {
      if (data?.orders[i].updateType !== "SWAP" && data?.orders[i].status === "OPEN") {
        const ID = data?.orders[i].id;
        const details = await getOrderData(ID);

        const updatedOrder = {
          ...data?.orders[i],
          collateralAsset: configs.tokensType[data?.orders[i].collateralToken.substring(1)],
          collateral: ethers.utils.formatUnits(data?.orders[i].collateralValue, 30),
          indexAsset: configs.tokensType[details.indexToken.substring(1).toLowerCase()],
          payAsset: data?.orders[i].payToken === configs.subgraphNativeAddress ? configs.nativeToken : configs.tokensType[data?.orders[i].payToken.substring(1)],
          priceTrigger: ethers.utils.formatUnits(data?.orders[i].price, 12),
          sizeChangeValue: ethers.utils.formatUnits(data?.orders[i]?.sizeChange, 30),
          marketPrice: await getTokenPrice(configs.tokensType[details.indexToken.substring(1).toLowerCase()]),
        };
        ordersData.push(updatedOrder);
      }
    }

    let openPositions = [];
    for (let i = 0; i < data?.positions.length; i++) {
      if (data?.positions[i].status === "OPEN") {

        // const tokenAprice = await getTokenPrice(configs.tokensType[positionData?.perpOrders[i].payToken.substring(1)]);
        const getIndexTokenDetails = positionData?.positions.find((order) => order.id === data?.positions[i].id);

        let updatedOrder = {
          ...data?.positions[i],
          collateralAsset: configs.tokensType[data?.positions[i].collateralToken.substring(1)],
          indexAsset: configs.tokensType[getIndexTokenDetails.indexToken.substring(1)],
          priceTrigger: ethers.utils.formatUnits(data?.positions[i].entryPrice, 12),
          collateral: ethers.utils.formatUnits(data?.positions[i].collateralValue, 30),
          sizeChangeValue: ethers.utils.formatUnits(data?.positions[i].size, 30),
          marketPrice: await getTokenPrice(configs.tokensType[getIndexTokenDetails.indexToken.substring(1)]),
          side: data?.positions[i].side === 0 || data?.positions[i].side === "LONG" ? 0 : 1,
        };
        const posDetails = await getPosLiquidationPrice(updatedOrder.id, updatedOrder.indexAsset, updatedOrder.side, updatedOrder.collateral, updatedOrder.sizeChangeValue);

        updatedOrder = {
          ...updatedOrder,
          closeFee: posDetails.posCloseFee,
          estimatedPnl:
            updatedOrder.side === 0
              // priceTrigger  = entryPrice
              ? (Number(updatedOrder.marketPrice) - Number(updatedOrder.priceTrigger)) * (Number(updatedOrder.sizeChangeValue) / Number(updatedOrder.priceTrigger))
              : (Number(updatedOrder.priceTrigger) - Number(updatedOrder.marketPrice)) * (Number(updatedOrder.sizeChangeValue) / Number(updatedOrder.priceTrigger)),
          liquidationPrice: posDetails.posLiquidationPrice,
          borrowFee: posDetails.posBorrowFee,
        };

        updatedOrder = {
          ...updatedOrder,
          netValue: Number(updatedOrder.collateral) + updatedOrder.estimatedPnl - updatedOrder.closeFee - updatedOrder.borrowFee,
          isProfit: updatedOrder.estimatedPnl >= 0,
        };

        openPositions.push({
          ...updatedOrder,
          leverage: Number(updatedOrder.sizeChangeValue) / updatedOrder.netValue,
        });

      }
    }

    ordersData = ordersData.sort((a, b) => b.id - a.id);
    history = history.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);
    openPositions = openPositions.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);

    setTableData({
      allOrders: ordersData,
      allHistory: history,
      allPositions: openPositions,
    });
    setDataFetching(false);
  };

  const handleRefresh = async () => {
    if (accountBalance !== undefined) {
      setDataFetching(true);
      await refetch();
      await reRender();
      await fetchOrderDetails();
    }
  };

  return (
    <div className="shadowed-box order-details-wrappper position-relative">
       <span
         className="d-flex align-items-center justify-content-center gap-1 refresh_btn position-absolute pointer-cursor"
         onClick={handleRefresh}><img className={`${dataFetching && "rotate"}`} src={loaderSvg} height={16}
                                      width={16} /> Refresh</span>
      {/*<span className="refresh_btn card_desc_tag position-absolute pointer-cursor"*/}
      {/*      onClick={handleRefresh}>Refresh</span>*/}

      <Tabs
        defaultActiveKey="positions"
        id="uncontrolled-tab-example"
        className="trade-details-tabs swap-details-tabs"
      >
        <Tab eventKey="positions" title="Positions">
          {dataFetching ? (
            <div className="trade_history_loader w-100">
              <div className="loading">Loading</div>
            </div>
          ) : (
            <PositionsTab tableData={tableData}
                          setCompleteTransactionModal={setCompleteTransactionModal}
                          setOrderMessage={setOrderMessage} />
          )}
        </Tab>

        <Tab eventKey="orders" title="Orders">
          {dataFetching ? (
            <div className="trade_history_loader w-100">
              <div className="loading">Loading</div>
            </div>
          ) : (
            <OrdersTab tableData={tableData}
                       setCompleteTransactionModal={setCompleteTransactionModal}
                       setOrderMessage={setOrderMessage} />
          )}
        </Tab>

        <Tab eventKey="history" title="History">
          {dataFetching ? (
            <div className="trade_history_loader w-100">
              <div className="loading">Loading</div>
            </div>
          ) : (
            <HistoryTab tableData={tableData} />
          )}
        </Tab>

      </Tabs>

      {completeTransactionModal && (
        <CompleteTransaction
          orderMessage={orderMessage}
          show={completeTransactionModal}
          successMessage={orderMessage}
          onHide={() => window.location.reload()}
        />
      )}

    </div>
  );
};

export default TradeDetail;
