import { toast } from "react-toastify";
import failImg from "../../assets/images/failed-icon.svg";
import { toastOptions } from "./ToastOptions";

export const handleError = (e) => {
  console.log("e--", e);
  toast.dismiss("waiting");
  toast.dismiss("loading");
  toast.error(
    <div className="d-flex align-items-center justify-content-center gap-2 flex-column">
      <p className="toast_heading">
        <img src={failImg} alt="fail" />
        Transaction failed!
      </p>
      <p className="toast_message">
        {e.code === "INSUFFICIENT_FUNDS"
          ? "Insufficient balance."
          : e.code === "ACTION_REJECTED"
          ? "Transaction rejected."
          : e.code === "UNPREDICTABLE_GAS_LIMIT"
          ? "Something went wrong."
          : "Transaction failed."}
      </p>
    </div>,
    { ...toastOptions, type: "error", style: { backgroundColor: "#272320", color: "#FF0000" } }
  );
};