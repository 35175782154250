// all the business logic will go here in container
// the page will be passed with all the props needed inside

import Page from "./Page";

import { ReactComponent as TotalValue } from "../../assets/images/totallocked.svg";
import { ReactComponent as TotalTrade } from "../../assets/images/totaltrading.svg";
import { ReactComponent as AccruedFee } from "../../assets/images/accruedfee.svg";
import { ReactComponent as Interest } from "./assets/interestIcon.svg";
import { ReactComponent as Wallet } from "../../assets/images/walletIcon.svg";
import { useEffect, useState } from "react";

const Container = () => {
  const metricsData = [
    { title: "Total Value Locked", value: "$25,658.764", profit: "$7,942,354 (24h)", img: <TotalValue /> },
    { title: "Total Trading Volume", value: "$3,659,124,374", profit: "$728,018.42 (24h)", img: <TotalTrade /> },
    { title: "Current Open Interest", value: "$2,487,231", profit: "$728,018.42 (24h)", img: <Interest /> },
    { title: "Accrued Fees", value: "$4,379,264", profit: "$728,018.42 (24h)", img: <AccruedFee /> },
    { title: "Number of Wallets", value: "3,927", profit: "946 (24h)", img: <Wallet /> },
  ];
  const [metricsDetails, setMetricsDetails] = useState();
  const [AUMDetails, setAUMDetails] = useState();
  const [feeVolumeDetails, setFeeVolumeDetails] = useState();
  const [OIDetails, setOIDetails] = useState(); // Open Interest (By Tokens / Long vs Short)
  const [stakingDetails, setStakingDetails] = useState(); // DAO / LVL / LGO staking

  useEffect(() => {
    fetchApiData();
  }, []);

  const fetchApiData = () => {};

  return (
    <div className="swap container">
      <Page metricsData={metricsData} />
    </div>
  );
};

export default Container;
