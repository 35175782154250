// Will mostly have only UI component imports and placement in this file.

import { useEffect, useState } from "react";
import { Col, Tab, Tabs } from "react-bootstrap";
import { useCommonStateContext } from "../../../hooks/commonStateContext";

import { supportedTokens } from "../../../components/static/SupportedTokens";
import PlButton from "../../../components/buttons/Button";
import Confirm from "../../../components/modals/Confirm";
import CompleteTransaction from "../../../components/modals/CompleteTransaction";
import { PercentageButtons } from "../../../components/buttons/Percentage";
import { SlippageRow } from "../../../components/SlippageRow";
import WithTagSelect from "../../../components/inputFields/WithTagSelect";
import WithTag from "../../../components/inputFields/WithTag";

import {
  addLiquidity,
  approve, approvedTranche,
  calcAddLiquidity,
  calcRemoveLiquidity,
  getApprovedAmount,
  getTokenPrice,
  poolApprove, removeLiquidity, trancheClaim, trancheRewards,
} from "../../../io/kava";
import configs from "../../../config.json";
import { waitingToast } from "../../../components/toasts/Waiting";
import { successToast } from "../../../components/toasts/Success";
import { handleError } from "../../../components/toasts/Error";
import { useMetaMask } from "metamask-react";
import { ReactComponent as InfoTag } from "../../../assets/images/info_outline.svg";
import { toolTipData } from "../../../components/static/ToolTipData";
import ReactTooltip from "react-tooltip";
import config from "../../../config.json";
import { useWeb3ConnectContext } from "../../../hooks/web3ConnectContext";

const BuySell = ({
                   trancheType,
                   trancheConditions,
                   setTrancheConditions,
                   isSwitchOn,
                   setIsSwitchOn,
                 }) => {

  const [confirmLiquidityModal, setConfirmLiquidityModal] = useState(false); // to show and hide confirmation  modal on buy/sell
  const [amountIn, setAmountIn] = useState(""); //user input the amount to add liquidity
  const [amountOut, setAmountOut] = useState(""); //user get tranche amount according amountIn and selected token
  const [minimumReceived, setMinimumReceived] = useState(""); // user received amount according amountOut and slippage
  const [feeAmount, setFeeAmount] = useState(0);
  const { account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();
  const { setLoading, setShowConfirmModal, setExplorerURL } = useCommonStateContext();
  const { setUsdValue, showConfirmModal, handleCloseSuccessModal } = useCommonStateContext();

  const handleShowModal = async () => {
    setConfirmLiquidityModal(true);
    let getFrom;
    let getTo;
    if (trancheConditions.selectedTrancheTab === "buy") {
      const fromPrice = await getTokenPrice(trancheConditions.selectedToken.value);
      getFrom = Number(fromPrice) * Number(amountIn);
      getTo = Number(amountOut) * trancheConditions.tranchePrice;
    } else {
      getFrom = Number(amountIn) * trancheConditions.tranchePrice;
      const toPrice = await getTokenPrice(trancheConditions.selectedToken.value);
      getTo = Number(toPrice) * Number(amountOut);
    }
    setUsdValue({
      fromUsdValue: parseFloat(Number(getFrom).toFixed(2)),
      toUsdValue: parseFloat(Number(getTo).toFixed(2)),
    });
  };

  useEffect(() => {
    setAmountOut("");
    setAmountIn("");
  }, [trancheConditions.selectedTrancheTab]);

  useEffect(() => {
    if (account !== null) {
      let isUnmounted = false;
      // for get llp value according input asset
      const countAmountOut = async () => {
        console.log("test1---amountIn--", amountIn);
        try {
          let calLpAmount;
          if (trancheConditions.selectedTrancheTab === "buy") {
            calLpAmount = await calcAddLiquidity(
              configs.tranches[trancheType],
              trancheConditions.selectedToken.value,
              amountIn,
            );
          } else {
            calLpAmount = await calcRemoveLiquidity(
              configs.tranches[trancheType],
              trancheConditions.selectedToken.value,
              amountIn,
            );
          }
          console.log("test1---calLpAmount--", calLpAmount.lpAmount);
          if (!isUnmounted) {
            setFeeAmount(Number(calLpAmount.daoFee) * 100);
            console.log("fees--", Number(calLpAmount.daoFee) * 100);
            if (Number(calLpAmount.lpAmount) !== 0) {
              setAmountOut(Number(calLpAmount.lpAmount));
            } else {
              setAmountOut("");
            }
          }
        } catch (e) {
          setAmountOut("");
          setFeeAmount(0);
        }

      };
      countAmountOut();

      return () => {
        isUnmounted = true;
      };
    }
  }, [trancheConditions.selectedToken, amountIn, account]);

  //for slippage count according selected marketSlippage and amountOut
  useEffect(() => {
    console.log("amountOut-", amountOut);
    const slippageCount = Number(amountOut) - (Number(amountOut) * Number(trancheConditions.slippage.value)) / 100;
    console.log("slippageCount--", slippageCount);
    setMinimumReceived(slippageCount > 0 ? slippageCount : "");
  }, [amountOut, trancheConditions.slippage]);

  //for approve amount of selected asset(from) or selected tranche
  const handleTrancheApprove = async () => {
    setLoading(true);
    try {
      waitingToast(
        `Approving ${
          trancheConditions.selectedTrancheTab === "buy"
            ? trancheConditions.selectedToken.value
            : `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp`
        }`,
      );

      {
        trancheConditions.selectedTrancheTab === "buy"
          ? await approve(trancheConditions.selectedToken.value, isSwitchOn ? configs.trade.lampMaster : configs.trade.liquidityRouter)
          : await poolApprove(trancheType, isSwitchOn ? configs.trade.lampMaster : configs.trade.liquidityRouter);
      }

      successToast(
        `${
          trancheConditions.selectedTrancheTab === "buy"
            ? trancheConditions.selectedToken.value
            : `${trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} llp`
        } Approved!`,
      );

      const amount = trancheConditions.selectedToken.value === config.nativeToken
        ? accountBalance
        : await getApprovedAmount(account, trancheConditions.selectedToken.value, isSwitchOn ? configs.trade.lampMaster : configs.trade.liquidityRouter);
      const trancheAmt = await approvedTranche(trancheType, account, isSwitchOn ? configs.trade.lampMaster : configs.trade.liquidityRouter);

      setTrancheConditions({
        ...trancheConditions,
        approvedTokenAmount: Number(amount),
        approvedTrancheAmount: Number(trancheAmt),
      });
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  //to execute add or remove liquidity
  const handleBuySell = async () => {
    console.log("trancheTypeInBuy---", trancheType);
    setLoading(true);
    try {
      waitingToast(
        trancheConditions.selectedTrancheTab === "buy"
          ? `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheConditions.selectedToken.value
          } to get ${parseFloat(Number(amountOut).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } LLP`
          : `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } LLP to get ${parseFloat(Number(amountOut).toFixed(5))} ${trancheConditions.selectedToken.value}`,
      );

      let res;
      if (trancheConditions.selectedTrancheTab === "buy") {
        res = await addLiquidity(
          trancheType,
          trancheConditions.selectedToken.value,
          amountIn,
          minimumReceived,
          account,
          amountOut,
          isSwitchOn,
        );
      } else {
        res = await removeLiquidity(
          trancheType,
          trancheConditions.selectedToken.value,
          amountIn,
          minimumReceived,
          account,
          amountOut,
          isSwitchOn,
        );
      }

      successToast(
        trancheConditions.selectedTrancheTab === "buy"
          ? `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheConditions.selectedToken.value
          } to get ${parseFloat(Number(amountOut).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } LLP`
          : `Selling ${parseFloat(Number(amountIn).toFixed(5))} ${
            trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"
          } LLP to get ${parseFloat(Number(amountOut).toFixed(5))} ${trancheConditions.selectedToken.value}`,
      );

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleClaim = async () => {
    setLoading(true);
    try {
      waitingToast(`Claiming Lamp`);
      const res = await trancheClaim(trancheType === "senior" ? 0 : trancheType === "mezzanine" ? 1 : 2, account);

      successToast(`Lamp claimed.`);
      const unclaimBalance = await trancheRewards(trancheType === "senior" ? 0 : trancheType === "junior" ? 2 : 1, account);
      setTrancheConditions({
        ...trancheConditions,
        unClaimRewards: unclaimBalance,
      });
    } catch (e) {
      handleError(e);
    }

    setLoading(false);
  };
  console.log("trancheConditions.stakingBalance-", trancheConditions.stakingBalance);
  console.log("amountIn-", amountIn);
  return (
    <>
      <Col className="mt-2" md={4}>
        <p className="swap_card_details_p d-flex justify-content-between align-items-center">
          Unclaim Rewards
          : {trancheConditions.unClaimRewards ? Number(trancheConditions?.unClaimRewards) >= 0.00001 ? parseFloat(Number(trancheConditions.unClaimRewards).toFixed(5)) : "0" : "-"} LAMP <span
          className={`m-0 card_desc_tag ${(accountBalance === undefined || Number(trancheConditions.unClaimRewards) < 0.00001) && "disabled"}`}
          onClick={handleClaim}>Claim</span>
        </p>
        <div className="shadowed-box">
          <Tabs
            onSelect={(k) => setTrancheConditions({ ...trancheConditions, selectedTrancheTab: k })}
            activeKey={trancheConditions.selectedTrancheTab}
            id="pool-tab"
            className="mb-3 earn-details-tabs pool_tabs"
          >
            <Tab eventKey="buy" title="BUY">
              <div className="mt-4">
                <div className="d-flex align-items-center gap-3 mb-4">
                  <span
                    className={`switch_text && ${!isSwitchOn && "fw-600"}`}>Buy {trancheType === "senior" ? "Senior" : trancheType === "junior" ? "Junior" : "Mezzanine"} LLP</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="check_id"
                      checked={isSwitchOn}
                      onChange={() => setIsSwitchOn(!isSwitchOn)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span
                    className={`switch_text && ${isSwitchOn && "fw-600"}`}>Buy & Stake</span>
                </div>
                <WithTagSelect
                  label={"Amount"}
                  placeholder={"0.0"}
                  value={trancheConditions.selectedToken}
                  inputValue={amountIn}
                  onChange={(e) => setAmountIn(e.target.value)}
                  onSelectChange={(val) => setTrancheConditions({ ...trancheConditions, selectedToken: val })}
                  optionValue={supportedTokens}
                  className={Number(amountIn) > trancheConditions.tokenBalance && "loss"}
                />
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <span className="balance">
                    Bal: {trancheConditions?.tokenBalance ? parseFloat(Number(trancheConditions?.tokenBalance)?.toFixed(5)) : "-"}{" "}
                    {trancheConditions.selectedToken?.value}
                  </span>
                  <PercentageButtons balance={trancheConditions.tokenBalance} setAmountIn={setAmountIn} />
                </div>
              </div>
              <div className="mt-4">
                <WithTag
                  label={"Receive"}
                  inputClass={"cursor_not_allowed profit"}
                  placeholder={"0.0"}
                  inputValue={amountOut}
                  tag={
                    <div className="d-flex align-items-center gap-1">
                      <div className="lamp_tag eth_lamp"></div>
                      {trancheType.toUpperCase()} LLP
                    </div>
                  }
                />
              </div>

              <div className="mt-4">
                <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                  <span className="d-flex align-items-center gap-1">Minimum Received <InfoTag
                    className="info_icon" data-tip={toolTipData.minimumReceived} /></span>
                  <ReactTooltip backgroundColor="black" className="react_tooltip" arrowColor="black" place="top"
                                type="dark" effect="solid" />
                  <span className="fw-600">
                    {Number(minimumReceived) > 0
                      ? `${parseFloat(minimumReceived?.toFixed(5))} ${trancheType} LLP`
                      : "-"}
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                  Weight/Target <span
                  className="fw-600">
                  {(trancheConditions.weightValue && trancheConditions.targetValue) ?
                    `${trancheConditions.weightValue?.toFixed(2)}%/${trancheConditions.targetValue}%`
                    : "-"}
                </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center">
                  Fees <span className="fw-600">{Number(amountOut) > 0 ? `${feeAmount.toFixed(2)}%` : "-"}</span>
                </p> <SlippageRow allDetails={trancheConditions} setAllDetails={setTrancheConditions} />
              </div>

              <div className="mt-4">
                <PlButton
                  disabled={accountBalance === undefined || Number(amountIn) === 0 || Number(trancheConditions?.tokenBalance) < Number(amountIn)}
                  label={accountBalance === undefined
                    ? `buy ${trancheType} llp`
                    : Number(amountIn) === 0
                      ? "enter an amount"
                      : Number(trancheConditions?.tokenBalance) < Number(amountIn)
                        ? "insufficient funds"
                        : trancheConditions.approvedTokenAmount < Number(amountIn)
                          ? "approve"
                          : `buy ${trancheType} llp`
                  }
                  onClick={() =>
                    trancheConditions.approvedTokenAmount < Number(amountIn)
                      ? handleTrancheApprove()
                      : handleShowModal()
                  }
                />
              </div>
            </Tab>
            <Tab eventKey="sell" title="SELL">
              <div className="mt-4">
                <div className="d-flex align-items-center gap-3 mb-4">
                  <span className={`switch_text && ${!isSwitchOn && "fw-600"}`}>Sell from Wallet</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="check_id"
                      checked={isSwitchOn}
                      onChange={() => setIsSwitchOn(!isSwitchOn)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className={`switch_text && ${isSwitchOn && "fw-600"}`}>Withdraw & Sell</span>
                </div>
                <WithTag
                  inputClass={
                    isSwitchOn
                      ? Number(amountIn) > Number(trancheConditions.stakingBalance) && "loss"
                      : Number(amountIn) > Number(trancheConditions.trancheBalance) && "loss"
                  }
                  label={"Amount"}
                  placeholder={"0.0"}
                  inputValue={amountIn}
                  onChange={(e) => setAmountIn(e.target.value)}
                  tag={
                    <div className="d-flex align-items-center gap-1">
                      <div className="lamp_tag eth_lamp"></div>
                      {trancheType.toUpperCase()} LLP
                    </div>
                  }
                />
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <span className="balance">
                    {isSwitchOn ? "Staking" : "Wallet"} Bal:{" "}
                    {isSwitchOn
                      ? trancheConditions.stakingBalance ? parseFloat(Number(trancheConditions.stakingBalance).toFixed(5)) : "-"
                      : trancheConditions.trancheBalance ? parseFloat(Number(trancheConditions.trancheBalance).toFixed(5)) : "-"}{" "}
                    LLP
                  </span>
                  <PercentageButtons setAmountIn={setAmountIn}
                                     balance={isSwitchOn ? trancheConditions.stakingBalance : trancheConditions.trancheBalance} />
                </div>
              </div>
              <div className="mt-4">
                <WithTagSelect
                  label={"Receive"}
                  placeholder={"0.0"}
                  value={trancheConditions.selectedToken}
                  inputValue={amountOut}
                  onSelectChange={(val) => setTrancheConditions({ ...trancheConditions, selectedToken: val })}
                  optionValue={supportedTokens}
                  className={`cursor_not_allowed ${Number(amountOut) > Number(trancheConditions.tokenFund) ? "loss" : "profit"}`}
                />
              </div>

              <div className="mt-4">
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                   <span className="d-flex align-items-center gap-1">Minimum Received <InfoTag
                     className="info_icon" data-tip={toolTipData.minimumReceived} /></span>
                  <ReactTooltip backgroundColor="black" className="react_tooltip" arrowColor="black" place="top"
                                type="dark" effect="solid" />
                  <span className="fw-600">
                    {Number(minimumReceived) > 0
                      ? `${parseFloat(minimumReceived?.toFixed(5))} ${trancheConditions.selectedToken.value}`
                      : "-"}
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  Available Fund{" "}
                  <span className="fw-600">
                    {trancheConditions.tokenFund ? parseFloat(trancheConditions.tokenFund?.toFixed(5)) : "-"} {trancheConditions.selectedToken.value}
                  </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  Weight/Target <span className="fw-600">
                  {(trancheConditions.weightValue && trancheConditions.targetValue)
                    ? `${trancheConditions.weightValue?.toFixed(2)}%/${trancheConditions.targetValue}%` : "-"}
                </span>
                </p>
                <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-3">
                  Fees <span className="fw-600">{Number(amountIn) > 0 ? `${feeAmount.toFixed(2)}%` : "-"}</span>
                </p>
                <SlippageRow allDetails={trancheConditions} setAllDetails={setTrancheConditions} />
              </div>

              <div className="mt-4">
                <PlButton
                  disabled={accountBalance === undefined ||
                  Number(amountIn) === 0 ||
                  Number(amountOut) <= 0 ||
                  (Number(amountOut) > Number(trancheConditions.tokenFund)) ||
                  (isSwitchOn ? Number(trancheConditions.stakingBalance) : Number(trancheConditions?.trancheBalance)) <
                  Number(amountIn)
                  }
                  label={accountBalance === undefined
                    ? `sell ${trancheType} llp`
                    : Number(amountIn) === 0
                      ? "enter an amount"
                      : (Number(amountOut) <= 0 || Number(amountOut) > Number(trancheConditions.tokenFund))
                        ? "insufficient liquidity"
                        : (isSwitchOn ? Number(trancheConditions.stakingBalance) : Number(trancheConditions?.trancheBalance)) <
                        Number(amountIn)
                          ? "insufficient funds"
                          : trancheConditions.approvedTrancheAmount < Number(amountIn) && !isSwitchOn
                            ? "approve"
                            : `sell ${trancheType} llp`
                  }
                  onClick={() =>
                    trancheConditions.approvedTrancheAmount < Number(amountIn) && !isSwitchOn
                      ? handleTrancheApprove()
                      : handleShowModal()
                  }
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </Col>

      {confirmLiquidityModal && (
        <Confirm
          title={`CONFIRM ${trancheConditions.selectedTrancheTab.toUpperCase()} LIQUIDITY`}
          show={confirmLiquidityModal}
          trancheType={trancheType}
          onHide={() => setConfirmLiquidityModal(false)}
          modalDetails={{ ...trancheConditions, trancheFee: feeAmount }}
          amountIn={amountIn}
          amountOut={amountOut}
          minimumReceived={minimumReceived}
          handleBuySell={handleBuySell}
        />
      )}

      {showConfirmModal && (
        <CompleteTransaction
          show={showConfirmModal}
          onHide={handleCloseSuccessModal}
          trancheType={trancheType}
          trancheConditions={trancheConditions}
          amountIn={amountIn}
          amountOut={amountOut}
        />
      )}
    </>
  );
};

export default BuySell;
