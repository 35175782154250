import { useEffect } from "react";
import { useMetaMask } from "metamask-react";
import { Route, Routes } from "react-router-dom";
import { useWeb3ConnectContext } from "./hooks/web3ConnectContext";
import { ToastContainer } from "react-toastify";
import { AllRoutes } from "./components/static/Routes";
import Header from "./components/Header";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const { handleWeb3Connect } = useWeb3ConnectContext();
  const { account, chainId } = useMetaMask();

  useEffect(() => {
    handleWeb3Connect();
  }, [account, chainId]);

  return (
    <>
      <ToastContainer />
      <Header />
      <Routes>
        {AllRoutes.map(({ Component, path, isPrivate }, i) => (
          <Route exact={true} path={path} key={i} element={<Component />} />
        ))}
      </Routes>
    </>
  );
};

export default App;
