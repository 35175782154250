import { gql } from "@apollo/client";
import axios from "axios";

export const GET_HISTORIES = gql`
  query histories($owner: Bytes!, $skip: Int!, $updateType: String!) {
    histories(skip: $skip,first:1000, where: { owner: $owner, updateType: $updateType }) {
      id
      createdAtTimestamp
      tx
      type
      status
      triggerPrice
      executionPrice
      amountIn
      amountOut
      tokenIn
      tokenOut
      minAmountOut
    }
    orders(skip: $skip,first:1000, where: { owner: $owner, updateType: $updateType }) {
      id
      tx
      type
      status
      price
      executionPrice
      amountIn
      amountOut
      tokenIn
      tokenOut
      minAmountOut
    }
  }
`;

export const GET_CHART_PRICE = gql`
  query takeQuery($start: Int!, $end: Int!, $period: Period!, $from: Bytes!, $to: Bytes!) {
    tokenFrom: priceStats(
      where: { token: $from, timestamp_gte: $start, timestamp_lt: $end, period: $period }
      orderBy: timestamp
      orderDirection: asc
      first: 1000
    ) {
      token
      value
      timestamp
    }
    tokenTo: priceStats(
      where: { token: $to, timestamp_gte: $start, timestamp_lt: $end, period: $period }
      orderBy: timestamp
      orderDirection: asc
      first: 1000
    ) {
      token
      value
      timestamp
    }
  }
`;

export const GET_TRADE_HISTORIES = gql`
  query histories($owner: Bytes!, $skip: Int!,$first:Int!) {
    histories(skip: $skip,first:$first, where: { owner: $owner }, orderBy: createdAtTimestamp, orderDirection: asc) {
      id
      createdAtTimestamp
      tx
      type
      status
      triggerPrice
      executionPrice
      collateralToken
      collateralValue
      side
      size
      updateType
    }
    orders(skip: $skip,first:$first, where: { owner: $owner }, orderBy: submissionTimestamp, orderDirection: desc) {
      id
      tx
      type
      status
      price
      executionPrice
      collateralToken
      collateralValue
      payToken
      side
      sizeChange
      submissionBlock
      submissionTimestamp
      updateType
      triggerAboveThreshold
    }
    positions(skip: $skip,first:$first, where: { owner: $owner }, orderBy: createdAtTimestamp, orderDirection: desc) {
      collateralToken
      collateralValue
      createdAtTimestamp
      entryInterestRate
      id
      entryPrice
      leverage
      owner
      realizedPnl
      reserveAmount
      side
      status
      size
    }
  }
`;

export const GET_TRADE_POSITIONS = gql`
  query positions($owner: Bytes!) {
    positions(where: { owner_: { id: $owner } }) {
      side
      collateralToken
      id
      status
      indexToken
    }
    perpOrders(where: { owner_: { id: $owner } }) {
      side
      collateralToken
      id
      status
      indexToken
    }
  }
`;

export const TRANCHE_PRICE = gql`
  query tranchePrice($id: Bytes!, $start: Int!, $end: Int!, $exclude: Bytes!) {
    trancheStats(
      where: { tranche: $id, timestamp_gte: $start, timestamp_lte: $end, period: daily }
      orderBy: timestamp
      orderDirection: asc
    ) {
      llpPrice
      llpSupply
      timestamp
      trancheValue
    }
    tokenDistributionStats(
      where: { tranche: $id, timestamp_gte: $start, timestamp_lte: $end, period: daily, token_not: $exclude }
      orderBy: timestamp
      orderDirection: asc
      first: 1000
    ) {
      timestamp
      token
      value
    }
  }
`;

export const GET_BLOCK = gql`
  query blocks {
    _meta {
      block {
        number
      }
    }
  }
`;

export const TRADE_VOLUME = gql`
  query stats($b24: Int!) {
    trade0x65552a569fcf2857d55825f67f6151cf55bcf4c1: tradingPairStats(
      where: { indexToken: "0x65552a569fcf2857d55825f67f6151cf55bcf4c1", period: total }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    trade0x65552a569fcf2857d55825f67f6151cf55bcf4c1yesterday: tradingPairStats(
      where: { indexToken: "0x65552a569fcf2857d55825f67f6151cf55bcf4c1", period: total }
      block: { number: $b24 }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    trade0xae7c8fa4a689d6f4a2e02ceaadc95c76f0dbbfcc: tradingPairStats(
      where: { indexToken: "0xae7c8fa4a689d6f4a2e02ceaadc95c76f0dbbfcc", period: total }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    trade0xae7c8fa4a689d6f4a2e02ceaadc95c76f0dbbfccyesterday: tradingPairStats(
      where: { indexToken: "0xae7c8fa4a689d6f4a2e02ceaadc95c76f0dbbfcc", period: total }
      block: { number: $b24 }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    trade0xcacb10225ed6ceaa50877e98411408889c1031de: tradingPairStats(
      where: { indexToken: "0xcacb10225ed6ceaa50877e98411408889c1031de", period: total }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
    trade0xcacb10225ed6ceaa50877e98411408889c1031deyesterday: tradingPairStats(
      where: { indexToken: "0xcacb10225ed6ceaa50877e98411408889c1031de", period: total }
      block: { number: $b24 }
    ) {
      indexToken
      volume
      volumeUsd
      timestamp
    }
  }
`;

const BASE_URL = "https://api.level.finance/";

export const oracleValues = async () => {
  try {
    const { data } = await axios.get(BASE_URL + "oracle-price-v2/24h?chain=56");
    return data;
  } catch (err) {
    // console.error("Backend-error: " + err);
    return false;
  }
};

export const tokenPrices = async () => {
  try {
    const { data } = await axios.get(BASE_URL + "prices?chain=56");
    return data;
  } catch (err) {
    // console.error("Backend-error: " + err);
    return false;
  }
};

export const leaderBoardData = async (sortBy, sortOrder, page) => {
  try {
    const { data } = await axios.get(
      `https://terminal-api.level.finance/v7/traders?sortBy=${sortBy}&sortType=${sortOrder}&size=10&page=${page}`,
    );
    return data.data;
  } catch (err) {
    // console.error("Backend-error: " + err);
    return false;
  }
};
