import { ReactComponent as InfoTag } from "../../../assets/images/info_outline.svg";

import { posSizeTokens, supportedTokens, tokenImages } from "../../../components/static/SupportedTokens";
import WithTagSelect from "../../../components/inputFields/WithTagSelect";
import { toolTipData } from "../../../components/static/ToolTipData";
import { orderTypes } from "../../../components/static/OrderTypes";
import Select, { components } from "react-select";
import DropdownArrowBlack from "../../../assets/images/arrow-dropdown-black.svg";

export const AllDataRows = ({ tradeConditions, liquidationPrice, swapFee, leverage, orderType, collateralValue }) => {
  return (
    <div className="mt-4">
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Collateral Asset{" "}
        <span className="fw-600 d-flex align-items-center gap-1 justify-content-center">
          <img
            src={
              tokenImages[tradeConditions.selectedTradeTab === "long" ? tradeConditions.selectedTo.value : "USDT"].image
            }
            height={14}
            width={14}
          />
          {tradeConditions.selectedTradeTab === "long" ? tradeConditions.selectedTo.value : "USDT"}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Collateral Value{" "}
        <span className="fw-600">
          {Number(collateralValue) > 0 ? `$${parseFloat(collateralValue?.toFixed(2))} ` : "-"}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Leverage<span className="fw-600">{leverage}x</span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Entry Price{" "}
        <span className="fw-600">
          {tradeConditions.entryPrice ?
            `$${orderType.value === "marketOrder"
              ? parseFloat(Number(tradeConditions.entryPrice)?.toFixed(5))
              : parseFloat(Number(tradeConditions.newEntryPrice)?.toFixed(5))}`
            : "-"}
        </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Liquidation Price{" "}
        <span className="fw-600">
          {Number(liquidationPrice) > 0 ? `$${parseFloat(liquidationPrice?.toFixed(2))} ` : "-"}
        </span>
      </p>
      {tradeConditions.selectedTradeTab === "long" ?
        tradeConditions.selectedFrom !== tradeConditions.selectedTo && (
          <p className="swap_card_details_p d-flex justify-content-between align-items-center">
            Swap Fee {" "}
            <span className="fw-600">{swapFee > 0 ? `${swapFee.toFixed(2)}%` : "-"}</span>
          </p>
        ) : tradeConditions.selectedFrom.value !== "USDT" && (
        <p className="swap_card_details_p d-flex justify-content-between align-items-center">
          Swap Fee {" "}
          <span className="fw-600">{swapFee > 0 ? `${swapFee.toFixed(2)}%` : "-"}</span>
        </p>
      )}
      <p className="market_info">Market Info</p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center">
        Borrow Fee<span className="fw-600">
        {tradeConditions.borrowFeeRate ? `${parseFloat(tradeConditions.borrowFeeRate?.toFixed(6))}% per hour` : "-"}
      </span>
      </p>
      <p className="swap_card_details_p d-flex justify-content-between align-items-center mb-0">
        Available Liquidity{" "}
        <span className="fw-600">
          {tradeConditions?.availableLiquidity ?
            `${parseFloat(Number(tradeConditions?.availableLiquidity)?.toFixed(5))} ${tradeConditions.selectedTradeTab === "long" ?
              tradeConditions.selectedTo.value : "USDT"}`
            : "-"}
        </span>
      </p>
    </div>
  );
};

export const OrderPrice = ({ orderType, setOrderType, setTradeConditions, tradeConditions }) => {
  return (
    <>
      <div>
        <label className='input_label'>{<span className="d-flex align-items-center gap-1">Price<InfoTag
          className="info_icon" data-tip={toolTipData.orderPrice} /></span>}</label>
        <div className="input-wrapper">
          <input
            type="number"
            onKeyPress={(event) => {
              if (event.charCode < 48 && event.charCode !== 46) {
                event.preventDefault();
              }
            }}
            min="0"
            placeholder={orderType.value === "marketOrder" && tradeConditions.entryPrice}
            value={orderType.value === "limitOrder" ? tradeConditions.newEntryPrice : ""}
            onChange={(e) =>
              setTradeConditions({
                ...tradeConditions,
                newEntryPrice: e.target.value.length > 0 ? e.target.value : tradeConditions.entryPrice,
              })}
            className={`${orderType.value === "marketOrder" && "cursor_not_allowed"}`}
          />
          <div className="d-flex align-items-center justify-content-end">
            <span onClick={() => setOrderType(orderTypes[0])}
                  className={`order_type_options ${orderType.value === "marketOrder" && "selected_type"}`}>Market</span>
            <span onClick={() => setOrderType(orderTypes[1])}
                  className={`order_type_options ${orderType.value === "limitOrder" && "selected_type"}`}>Limit</span>
          </div>
        </div>
      </div>
    </>
  );
};

export const LeverageComp = ({ leverage, setLeverage, tradeConditions }) => {
  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between">
        <label className="range_span" htmlFor="tempB"><span className="d-flex align-items-center gap-1">Leverage<InfoTag
          className="info_icon" data-tip={toolTipData.leverage} /></span>
        </label>
        <span className="range_data">{leverage}x</span>
      </div>
      <input
        type="range"
        onChange={(e) => setLeverage(e.target.value)}
        className="form-range"
        min="2"
        max={tradeConditions.maxLeverage}
        list="values"
        value={leverage}
      />
    </div>
  );
};

export const PositionSize = ({ tradeConditions, setTradeConditions }) => {

  return (
    <div className="tag-select token_select_tag d-flex align-items-center justify-content-center gap-3">
      <span>Market</span>
      <Select
        styles={{
          control: (base) => ({
            ...base,
            height: 24,
            minHeight: 24,
          }),
        }}
        defaultValue={posSizeTokens[0]}
        value={tradeConditions.selectedTo}
        onChange={(val) => setTradeConditions({ ...tradeConditions, selectedTo: val })}
        options={posSizeTokens}
        isClearable={false}
        hideSelectedOptions
        classNamePrefix="select select-custom"
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <img src={DropdownArrowBlack} alt="icon" className="icon" />
            </components.DropdownIndicator>
          ),
        }}
      />
    </div>
  );
};

export const AmountDetails = ({ amountIn, setAmountIn, setTradeConditions, tradeConditions }) => {
  return (
    <div className="mt-3 position-relative">
      {Number(amountIn) >0 &&
      <span className='position-absolute pay_amount_usd'>~ ${parseFloat((Number(amountIn) *Number(tradeConditions.TokenAPrice)).toFixed(2))}</span>
      }
      <WithTagSelect
        label={<span className="d-flex align-items-center gap-1">Pay<InfoTag
          className="info_icon" data-tip={toolTipData.orderPay} /></span>
        }
        className={Number(amountIn) > tradeConditions.tokenBalance && "loss"}
        placeholder={"0.0"}
        inputValue={amountIn}
        onChange={(e) => setAmountIn(e.target.value)}
        value={tradeConditions.selectedFrom}
        onSelectChange={(val) => setTradeConditions({ ...tradeConditions, selectedFrom: val })}
        optionValue={supportedTokens}
      />
    </div>
  );
};
