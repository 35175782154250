import { toast } from "react-toastify";
import processImg from "../../assets/images/processing icon.svg";
import { toastOptions } from "./ToastOptions";

export const loadingToast = (message) => {
  toast.dismiss("waiting");
  toast(
    <div className="d-flex align-items-center justify-content-center gap-2 flex-column">
      <p className="toast_heading">
        <img src={processImg} alt="process" />
        Processing transaction
      </p>
      <p className="toast_message">{message}</p>
    </div>,
    {
      ...toastOptions,
      autoClose: false,
      type: "warning",
      toastId: "loading",
      style: { color: "#FF8432", backgroundColor: "#272320" },
    }
  );
};