// Will mostly have only UI component imports and placement in this file.

import icon from "../../assets/images/liquidityIcon.svg";

import LampEthTranche from "./cards/LampEthTranche";
import TradingTranche from "./cards/TradingTranche";
import Token from "./cards/Token";

const Page = ({ lampEthData, tokenData, tokensDetails, tranchesDetails }) => {
  return (
    <>
      <h1 className="page_title dashboard_title d-flex align-items-center justify-content-start">
        <img src={icon} alt="" /> LIQUIDITY
      </h1>
      {/*<LampEthTranche lampEthData={lampEthData} />*/}
      <TradingTranche tranchesDetails={tranchesDetails} />
      <Token tokenData={tokenData} tokensDetails={tokensDetails} />
    </>
  );
};

export default Page;
