import React from "react";
import loader from "../../assets/images/loader.gif";

const Loader = ({ loading }) => {
  return (
    <>
      <div className={`loading_div ${loading && "loading_div_display"}`}>
        <div className="loading">Loading</div>
        {/*<img className="loading_img" src={loader} alt="loading.." />*/}
      </div>
    </>
  );
};

export default Loader;
