// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";

import icon from "./assets/swap_gradient.svg";

import Chart from "./cards/Chart";
import PlaceOrder from "./cards/PlaceOrder";
import SwapDetail from "../../components/SwapDetail";

const Page = ({
  swapConditions,
  setSwapConditions,
  amountIn,
  setAmountIn,
  amountOut,
  minimumReceived,
  handleSwapValue,
  handleSwap,
  handleApprove,
}) => {
  return (
    <>
      <Row>
        <h1 className="page_title d-flex align-items-center justify-content-start">
          <img src={icon} alt="" /> SWAP
        </h1>
        <Col md={8}>
          <Chart swapConditions={swapConditions} />
          <SwapDetail />
        </Col>
        <Col md={4}>
          <PlaceOrder
            swapConditions={swapConditions}
            setSwapConditions={setSwapConditions}
            amountIn={amountIn}
            setAmountIn={setAmountIn}
            amountOut={amountOut}
            minimumReceived={minimumReceived}
            handleSwapValue={handleSwapValue}
            handleApprove={handleApprove}
            handleSwap={handleSwap}
          />
        </Col>
      </Row>
    </>
  );
};

export default Page;
