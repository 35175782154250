import React, { createContext, useContext, useState } from "react";

const CommonStateContext = createContext();

function CommonStateContextProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [usdValue, setUsdValue] = useState({ fromUsdValue: 0, toUsdValue: 0 });
  const [explorerURL, setExplorerURL] = useState("");

  //for close last successfully message modal
  const handleCloseSuccessModal = () => {
    window.location.reload();
  };

  return (
    <CommonStateContext.Provider
      value={{
        loading,
        setLoading,
        showConfirmModal,
        setShowConfirmModal,
        usdValue,
        setUsdValue,
        explorerURL,
        setExplorerURL,
        handleCloseSuccessModal,
      }}
    >
      {children}
    </CommonStateContext.Provider>
  );
}

export default CommonStateContextProvider;

export const useCommonStateContext = () => useContext(CommonStateContext);
