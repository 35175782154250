// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";

const Status = ({ trancheStatusData, trancheConditions }) => {
  return (
    <Col md={8} className="mt-0">
      <Row>
        <div className="trading-section">
          <p className="title mt-0">Pool Status</p>
          <Row className="heading mx-0">
            {trancheStatusData?.columnTitle?.map((item, i) => (
              <Col key={i} className={item.className}>
                <p className="mx-0">{item.title}</p>
              </Col>
            ))}
          </Row>
          {trancheConditions.assetDetails?.map((item, i) => (
            <Row className="data shadowed-box p-0 mx-0" key={i}>
              <Col className="pl-4">
                <img src={item.image} height={20} width={20} />
                <span className="bold ms-2">{item.token}</span>
              </Col>
              <Col>
                <span>{item.amount ? parseFloat(item.amount.toFixed(3)) : "-"}</span>
              </Col>
              <Col>
                <span>{item.value ? `$${Math.round(item.value)}` : "-"}</span>
              </Col>
              <Col className="justify-content-center">
                <span>{item.utilization ? `${item.utilization.toFixed(2)}%` : "-"}</span>
              </Col>
              {/*<Col className="justify-content-center">*/}
              {/*  <span>-</span>*/}
              {/*</Col>*/}
            </Row>
          ))}
        </div>
      </Row>
    </Col>
  );
};

export default Status;
