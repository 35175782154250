import tether from "../../assets/images/usdt-coin.png";
import kava from "../../assets/images/Kava-Coin.png";
import eth from "../../assets/images/ethereum-coin.svg";
import bitcoin from "../../assets/images/bitcoin.png";
import cake from "../../assets/images/failed-icon.svg";

export const supportedTokens = [
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={tether} height={16} width={16} /> USDT
      </div>
    ),
    value: "USDT",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={kava} height={16} width={16} /> KAVA
      </div>
    ),
    value: "KAVA",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={eth} height={16} width={16} /> ETH
      </div>
    ),
    value: "ETH",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={bitcoin} height={16} width={16} /> BTC
      </div>
    ),
    value: "BTC",
  },
  // {
  //   label: (
  //     <div className="d-flex align-items-center gap-1">
  //       <img src={usd} height={16} width={16} /> USD
  //     </div>
  //   ),
  //   value: "USD",
  // },
];

export const posSizeTokens = [
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={kava} height={16} width={16} /> KAVA/USD
      </div>
    ),
    value: "KAVA",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={eth} height={16} width={16} /> ETH/USD
      </div>
    ),
    value: "ETH",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={bitcoin} height={16} width={16} /> BTC/USD
      </div>
    ),
    value: "BTC",
  },
];

export const TradeChartTokens = [
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={bitcoin} height={20} width={20} />
        BTC/USD
      </div>
    ),
    value: "BTC",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={eth} height={20} width={20} /> ETH/USD
      </div>
    ),
    value: "ETH",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <img src={kava} height={20} width={20} />
        KAVA/USD
      </div>
    ),
    value: "KAVA",
  },
];

export const tokenImages = {
  USDT: { image: tether },
  BTC: { image: bitcoin },
  ETH: { image: eth },
  KAVA: { image: kava },
  CAKE: { image: cake },
};
