// all the business logic will go here in container
// the page will be passed with all the props needed inside

import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Page from "./Page";
import configs from "../../config.json";

import { ReactComponent as TotalValue } from "../../assets/images/totallocked.svg";
import { ReactComponent as TotalTrade } from "../../assets/images/totaltrading.svg";
import { ReactComponent as AccruedFee } from "../../assets/images/accruedfee.svg";

import { GET_BLOCK, oracleValues, tokenPrices, TRADE_VOLUME } from "../../io/subgraph";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import Loader from "../../components/loader/Loader";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";
import { useMetaMask } from "metamask-react";
import {
  depositedGovernance,
  depositedPlatform,
  trancheSupply,
} from "../../io/kava";

const liquidityData = [
  { title: "Senior Pool", risk: "Low Risk", classname: "risk lowRisk" },
  { title: "Mezzanine Pool", risk: "Moderate Risk", classname: "risk moderateRisk" },
  { title: "Junior Pool", risk: "High Risk", classname: "risk highRisk" },
];

const tradingData = {
  columnTitle: [
    { title: "Pair", className: "" },
    { title: "Last Price", className: "" },
    { title: "24h Change", className: "" },
    { title: "24h High", className: "" },
    { title: "24h Low", className: "" },
    { title: "24h Volume", className: "" },
    { title: "24h Volume (USD)", className: "custom-flex1" },
    { title: "", className: "custom-flex" },
  ],
};

const Container = () => {
  const [tradingDetails, setTradingDetails] = useState();
  const [earnDetails, setEarnDetails] = useState();
  const [trancheAprDetails, setTrancheAprDetails] = useState();
  const [metricsDetails, setMetricsDetails] = useState();

  const [stats] = useLazyQuery(TRADE_VOLUME, { context: { clientName: "analytics" } });
  const [blocks] = useLazyQuery(GET_BLOCK, { context: { clientName: "analytics" } });
  const { setLoading, loading } = useCommonStateContext();
  const { accountBalance } = useWeb3ConnectContext();
  const { chainId, account } = useMetaMask();

  const metricsData = [
    { title: "Total Value Locked", value: metricsDetails ? metricsDetails?.totalValueLocked : 0, img: <TotalValue /> },
    {
      title: "Total Trading Volume",
      value: metricsDetails ? metricsDetails?.totalTradingVolume : 0,
      img: <TotalTrade />,
    },
    { title: "Accrued Fees", value: 0, img: <AccruedFee /> },
  ];

  const earnData = [
    { title: "LAMP Gov Staking", deposited: earnDetails?.totalLgo },
    { title: "LAMP Staking", deposited: earnDetails?.totalLvl },
  ];

  useEffect(() => {
    getAllDetails();
  }, [account, chainId, accountBalance]);

  //for get trading pairs , metricsDetails, apr details for liquidity tranches and earn details
  const getAllDetails = async () => {
    setLoading(true);
    const prices = await oracleValues();
    let tokenPriceDetails = [];
    prices.map((priceDetails) => {
      if (priceDetails.token !== "CAKE") {
        tokenPriceDetails.push({
          ...priceDetails,
          change: Number(priceDetails.close) - Number(priceDetails.open),
        });
      }
    });

    const { data: blockData } = await blocks();
    const { data } = await stats({
      variables: {
        b24: Number(blockData._meta.block.number) - configs.blockCount,
      },
    });

    const tokenCurrentPrices = await tokenPrices();
    let totalTradingVolume = 0;
    tokenPriceDetails = tokenPriceDetails.map((priceDetails) => {
      const priceData = tokenCurrentPrices.find((ele) => ele.token === priceDetails.token);

      const currentVolume = `trade${configs.tokens[
        priceData.token === "BNB" ? "KAVA" : priceData?.token
        ].toLowerCase()}`;
      const pastVolume = `trade${configs.tokens[
        priceData.token === "BNB" ? "KAVA" : priceData?.token
        ].toLowerCase()}yesterday`;

      if (data[currentVolume].length > 0) {
        totalTradingVolume += Number(data[currentVolume][0].volumeUsd) / configs.divideValue;
      }

      const volumeData = {
        volume:
          data[currentVolume].length > 0 && data[pastVolume].length > 0
            ? (Number(data[currentVolume][0].volume) - Number(data[pastVolume][0].volume)) / configs.divideVolume
            : 0,
        usdVolume:
          data[currentVolume].length > 0 && data[pastVolume].length > 0
            ? (Number(data[currentVolume][0].volumeUsd) - Number(data[pastVolume][0].volumeUsd)) / configs.divideValue
            : 0,
      };

      return {
        ...priceDetails,
        token: priceDetails.token === "BNB" ? "KAVA" : priceDetails.token,
        ...volumeData,
        price: priceData.price,
        changePercentage: (priceDetails.change / Number(priceData.price)) * 100,
      };
    });

    setTradingDetails(tokenPriceDetails);

    if (accountBalance !== undefined) {
      const totalValueLocked = await trancheSupply();
      setMetricsDetails({ totalValueLocked, totalTradingVolume });

      const totalLgo = await depositedGovernance();
      const totalLvl = await depositedPlatform();
      setEarnDetails({ totalLgo, totalLvl });
    } else {
      setEarnDetails({ totalLgo: 0, totalLvl: 0 });
    }

    setLoading(false);
  };

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page
        tradingDetails={tradingDetails}
        metricsData={metricsData}
        tradingData={tradingData}
        liquidityData={liquidityData}
        earnData={earnData}
      />
    </div>
  );
};

export default Container;
