import Select, { components } from "react-select";
import DropdownArrow from "../../assets/images/arrow-dropdown.svg";

const SelectField = ({ label, disabled, onSelectChange, optionValue, value }) => {
  return (
    <>
      {label && <label className='input_label'>{label}</label>}
      <div className="input-wrapper">
        <Select
          onChange={onSelectChange}
          defaultValue={optionValue[0]}
          value={value}
          options={optionValue}
          isClearable={false}
          hideSelectedOptions
          isDisabled={disabled}
          classNamePrefix="select select-custom"
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <img src={DropdownArrow} alt="icon" className="icon" />
              </components.DropdownIndicator>
            ),
          }}
        />
      </div>
    </>
  );
};

export default SelectField;
