// Will mostly have only UI component imports and placement in this file.

import { Col, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

const Token = ({ tokenData, tokensDetails }) => {
  console.log('tokensDetails-',tokensDetails);
  return (
    <>
      <Row>
        <div className="trading-section">
          <p className="title">Token Distribution</p>
          <Row className="heading mx-0">
            {tokenData?.columnTitle?.map((item, i) => (
              <Col key={i} className={item.className}>
                <p>{item.title}</p>
                <ReactTooltip backgroundColor="rgba(39, 35, 32, 1)" className="react_tooltip"
                              arrowColor="rgba(39, 35, 32, 1)" place="top" type="dark" effect="solid" />
              </Col>
            ))}
          </Row>
          {tokensDetails.map((item, i) => (
            <Row className="data shadowed-box p-0 mx-0" key={i}>
              <Col className="pl-4">
                <img src={item.image} height={20} width={20} />
                <span className="bold ms-2">{item.token}</span>
              </Col>
              <Col>
                <span className="liquidity-value">{item.price ? `$${parseFloat(item.price.toFixed(3))}` : "-"}</span>
              </Col>
              <Col>
                <span>{item.amount ? parseFloat(item.amount.toFixed(3)) : "-"}</span>
              </Col>
              <Col>
                <span>{item.value ? `$${Math.round(item.value).toLocaleString()}` : "-"}</span>
              </Col>
              <Col>
                <span>{item.weight && item.target ? `${item.weight.toFixed(2)}% / ${item.target}%` : "-/-"}</span>
              </Col>
              <Col className="justify-content-center">
                <span>{item?.utilization ? `${item.utilization.toFixed(2)}%` : "-"}</span>
              </Col>
            </Row>
          ))}
        </div>
      </Row>
    </>
  );
};

export default Token;
