// all the business logic will go here in container
// the page will be passed with all the props needed inside

import React, { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { useCommonStateContext } from "../../hooks/commonStateContext";
import Loader from "../../components/loader/Loader";
import Page from "./Page";
import configs from "../../config.json";

import {
  approve,
  balanceOf,
  calcSwapFee, countSwapFee,
  fees,
  getApprovedAmount, getLiquidity,
  getSwapAmount, getTokenPrice,
  limitSwap,
  marketSwap,
} from "../../io/kava";
import { supportedTokens } from "../../components/static/SupportedTokens";
import { slippageOptions } from "../../components/static/SlippageOptions";
import { waitingToast } from "../../components/toasts/Waiting";
import { successToast } from "../../components/toasts/Success";
import { handleError } from "../../components/toasts/Error";
import config from "../../config.json";
import { useWeb3ConnectContext } from "../../hooks/web3ConnectContext";

const Container = () => {
  const { setLoading, loading, setShowConfirmModal, setExplorerURL } = useCommonStateContext();
  const { chainId, account } = useMetaMask();
  const { accountBalance } = useWeb3ConnectContext();

  //The user inputs the amount to be swapped.
  const [amountIn, setAmountIn] = useState("");

  //The user receives the swapped amount based on the "amountIn" and selected tokens.
  const [amountOut, setAmountOut] = useState("");

  // The user receives the amount according to the specified "amountOut" and slippage.
  const [minimumReceived, setMinimumReceived] = useState("");

  //To store all swap conditions such as slippage, order type, token balance, and selected tokens.
  const [swapConditions, setSwapConditions] = useState({
    selectedFrom: supportedTokens[0],
    selectedTo: supportedTokens[3],
    slippage: slippageOptions[0],
    selectedSwapTab: "market",
  });

  useEffect(() => {
    if (accountBalance !== undefined) {
      getSwapDetails();
    }
  }, [accountBalance, account, chainId, swapConditions.selectedFrom, swapConditions.selectedTo]);

  useEffect(() => {
    let isUnmounted = false;
    //To obtain the swap value based on the provided amountIn.
    const countSwapAmountOut = async () => {
      console.log("#######");
      const _tokenIn = swapConditions.selectedFrom.value;
      const _tokenOut = swapConditions.selectedTo.value;
      try {
        let swapAmount;

        if (swapConditions.selectedSwapTab === "market") {
          swapAmount = await getSwapAmount(_tokenIn, _tokenOut, Number(amountIn));
        } else {
          const amountOut = Number(amountIn) * Number(swapConditions.swapLimitPrice);
          let feeAmount;
          try {
            feeAmount = await countSwapFee(_tokenIn, _tokenOut, Number(amountIn));
          } catch (e) {
            feeAmount = 0;
          }
          console.log("amountOut-", amountOut);
          const feeRate = (Number(feeAmount) / amountIn * 100);
          swapAmount = amountOut - (amountOut * feeRate / 100);
        }

        if (!isUnmounted) {
          console.log("swapAmount-", swapAmount);
          if (Number(swapAmount) !== 0) {
            setAmountOut(Number(swapAmount));
          } else {
            setAmountOut("");
          }
        }

      } catch (e) {
        setAmountOut("");
      }
    };
    countSwapAmountOut();

    return () => {
      isUnmounted = true;
    };
  }, [
    swapConditions.selectedTo,
    swapConditions.selectedFrom,
    swapConditions.selectedSwapTab,
    swapConditions.price,
    swapConditions.swapLimitPrice,
    amountIn,
  ]);

  // To calculate the slippage based on the selected market slippage and desired amountOut.
  useEffect(() => {
    const slippageCount = swapConditions.selectedSwapTab === "market" ?
      Number(amountOut) - (Number(amountOut) * Number(swapConditions.slippage.value)) / 100 :
      Number(amountOut);
    setMinimumReceived(slippageCount > 0 ? slippageCount : "");
  }, [amountOut, swapConditions.slippage, swapConditions.selectedSwapTab]);

  useEffect(() => {
    const interval = setInterval(async () => {
      setSwapConditions({
        ...swapConditions,
        price: await getPrices(),
      });
    }, 10000);

    return () => clearInterval(interval);
  });

  //To obtain the swap price of both selected tokens
  const getPrices = async () => {
    let price;
    try {
      price = await getSwapAmount(swapConditions.selectedFrom.value, swapConditions.selectedTo.value, 1);
    } catch (e) {
      const priceIn = await getTokenPrice(swapConditions.selectedFrom.value);
      const priceOut = await getTokenPrice(swapConditions.selectedTo.value);
      price = Number(priceIn) / Number(priceOut);
    }
    return Number(price);
  };

  //to get all initial details of selected assets
  const getSwapDetails = async () => {
    setLoading(true);
    console.log("$$$$$$$$$$$ -- in swap");

    const fromBalance = swapConditions.selectedFrom.value === config.nativeToken ? accountBalance : await balanceOf(swapConditions.selectedFrom.value, account);
    const price = await getPrices();
    const tokens = await getLiquidity(swapConditions.selectedTo.value);
    const approvedAmount = swapConditions.selectedFrom.value === config.nativeToken ? accountBalance : await getApprovedAmount(
      account,
      swapConditions.selectedFrom.value,
      configs.trade.orderManager,
    );

    setSwapConditions({
      ...swapConditions,
      approvedBalance: Number(approvedAmount),
      price,
      swapLimitPrice: Number(price),
      availableTokens: tokens.poolAmount - tokens.reserveAmount,
      fromBalance,
    });

    setLoading(false);
  };

  //for reverse selected assets
  const handleSwapValue = () => {
    setSwapConditions({
      ...swapConditions,
      selectedFrom: swapConditions.selectedTo,
      selectedTo: swapConditions.selectedFrom,
    });
  };

  //to approve amount of selected asset(from)
  const handleApprove = async () => {
    setLoading(true);
    try {
      waitingToast(`Approving ${swapConditions.selectedFrom.value}`);

      await approve(swapConditions.selectedFrom.value, config.trade.orderManager);

      successToast(`${swapConditions.selectedFrom.value} Approved!`);

      const approvedAmount = await getApprovedAmount(
        account,
        swapConditions.selectedFrom.value,
        config.trade.orderManager,
      );
      setSwapConditions({
        ...swapConditions,
        approvedBalance: Number(approvedAmount),
      });
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  //to create a market swap order
  const handleSwap = async () => {
    setLoading(true);
    try {
      waitingToast(
        swapConditions.selectedSwapTab === "market"
          ? `Swapping ${parseFloat(Number(amountIn).toFixed(5))} ${swapConditions.selectedFrom.value} for ${parseFloat(
          Number(amountOut).toFixed(5),
          )} ${swapConditions.selectedTo.value}`
          : `Creating order to swap ${parseFloat(Number(amountIn).toFixed(5))} ${
            swapConditions.selectedFrom.value
          } for ${parseFloat(Number(minimumReceived).toFixed(5))} ${
            swapConditions.selectedTo.value
          }`,
      );

      let res;
      {
        swapConditions.selectedSwapTab === "market"
          ? (res = await marketSwap(
          swapConditions.selectedFrom.value,
          swapConditions.selectedTo.value,
          amountIn,
          minimumReceived,
          amountOut,
          ))
          : (res = await limitSwap(
          swapConditions.selectedFrom.value,
          swapConditions.selectedTo.value,
          amountIn,
          minimumReceived,
          swapConditions.swapLimitPrice,
          ));
      }

      successToast(
        swapConditions.selectedSwapTab === "market"
          ? `Swapped ${parseFloat(Number(amountIn).toFixed(5))} ${swapConditions.selectedFrom.value} for ${parseFloat(
          Number(amountOut).toFixed(5),
          )} ${swapConditions.selectedTo.value}`
          : `Order created to swap ${parseFloat(Number(amountIn).toFixed(5))} ${
            swapConditions.selectedFrom.value
          } for ${parseFloat(Number(minimumReceived).toFixed(5))} ${
            swapConditions.selectedTo.value
          }`,
      );

      //This URL needs to be updated according to the Kava Explorer.
      setExplorerURL(`https://goerli.etherscan.io/tx/${res.hash}`);
      setShowConfirmModal(true);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  return (
    <div className="swap container">
      <Loader loading={loading} />
      <Page
        swapConditions={swapConditions}
        setSwapConditions={setSwapConditions}
        amountIn={amountIn}
        setAmountIn={setAmountIn}
        amountOut={amountOut}
        minimumReceived={minimumReceived}
        handleSwapValue={handleSwapValue}
        handleApprove={handleApprove}
        handleSwap={handleSwap}
      />
    </div>
  );
};

export default Container;
