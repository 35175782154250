import { toast } from "react-toastify";
import { toastOptions } from "./ToastOptions";

export const switchNetworkToast = (chainID) => {
  toast("Switch to Goerli Testnet", {
    ...toastOptions,
    toastId: chainID,
    autoClose: false,
    style: { backgroundColor: "#272320", color: "#E6BB00", textAlign: "center" },
  });
};