// Will mostly have only UI component imports and placement in this file.

import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import arrowSvg from "../../../assets/images/arrow-forward.svg";

import PlButton from "../../../components/buttons/Button";

const Earn = ({ earnData }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="trading-section">
        <p className="title">Earn</p>
        <Row xs={1} md={2} className="cards-deatils g-5">
          {earnData.map((item, id) => (
            <Col key={id}>
              <Card className="shadowed-box p-0 border-0 pointer-cursor" onClick={() => navigate("/earn")}>
                <Card.Body>
                  <div className="d-flex align-items-center gap-2">
                    <div className="earn_tag"></div>
                    <p className="liquidity_details bold mb-0">{item.title}</p>
                  </div>
                  <div className="d-flex gap-5 align-items-center justify-content-center">
                    <div>
                      <p className="mb-2">APR</p>
                      <span className="month_value">210.54%</span>
                    </div>
                    <div>
                      <p className="mb-2">Total Deposited</p>
                      <span className="month_value">{item.deposited}</span>
                    </div>
                    <PlButton
                      src={arrowSvg}
                      className="sqaure"
                      height={14}
                      width={14}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Earn;
